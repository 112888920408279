import { MEGATRON_CLIENT_ENDPOINT_PARAMETER_SMAUG } from '@configs/megatron-client';
import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

export default (megatronClient, $errorHandler) => ({
    async authorize({
        cartId,
        amount,
        cardNumber,
        cardPin,
        currency,
        extraPaymentMethod,
    }) {
        try {
            const payload = {
                amount,
                cardNumber,
                cardPin,
                currency,
            };

            if (extraPaymentMethod) {
                payload.extraPaymentMethod = extraPaymentMethod;
            }

            return await megatronClient.put(`v1/carts/${cartId}/giftcard`, {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_SMAUG,
                payload,
                context: {
                    useAuthorization: false,
                },
            });
        } catch (error) {
            $errorHandler.captureError(error, {
                [ERROR_ACTION_TAG_NAME]: 'services.payments.giftcard.authorize',
            });

            return error.data;
        }
    },

    async update({ cartId, newCartId }) {
        try {
            const payload = {
                cartId: newCartId,
            };

            return await megatronClient.patch(`v1/carts/${cartId}/giftcard`, {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_SMAUG,
                payload,
                context: {
                    useAuthorization: false,
                },
            });
        } catch (error) {
            $errorHandler.captureError(error, {
                [ERROR_ACTION_TAG_NAME]: 'services.giftcard.update',
            });

            return error.data;
        }
    },

    async removeCartGiftCardRelation(cartId) {
        try {
            return await megatronClient.delete(`v1/carts/${cartId}/giftcard`, {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_SMAUG,
                context: {
                    useAuthorization: false,
                },
            });
        } catch (error) {
            $errorHandler.captureError(error, {
                [ERROR_ACTION_TAG_NAME]:
                    'services.payments.giftcard.removeCartGiftCardRelation',
            });

            return error.data;
        }
    },
});
