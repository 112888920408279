import { isFilterActive } from '@assets/filters';
import { convertLocaleToIso } from '@assets/locale';
import { formatNumber } from '@assets/format-number';

import {
    SPECIAL_ACTION_CODE,
    PRODUCT_BADGE_FILTER_CODE,
    NEW_OPTION_CODE,
    OCCASION_OPTION_CODE,
    DISCOUNT_FILTER_CODE,
    TOGGLE,
} from '@types/Filters';

export default function normalizeFilters(
    filters,
    { locale, storeViewTimezone }
) {
    const { [SPECIAL_ACTION_CODE]: specialAction } = filters;

    if (specialAction) {
        const filterOptions = specialAction.options
            .filter(
                ({
                    values: { start_date: startDate, end_date: endDate } = {},
                }) => {
                    if (!startDate?.[locale] || !endDate?.[locale]) {
                        return;
                    }

                    return isFilterActive(
                        storeViewTimezone,
                        startDate[locale],
                        endDate[locale]
                    );
                }
            )
            .map(option => {
                const {
                    label,
                    values: { label_text: labelText },
                } = option;

                if (!labelText) {
                    return option;
                }

                return {
                    ...option,
                    label: labelText[locale] || label,
                };
            });

        filters[SPECIAL_ACTION_CODE] = {
            ...specialAction,
            options: filterOptions,
        };
    }

    const productBadgeFilter = Object.values(filters).find(
        ({ code }) => code === PRODUCT_BADGE_FILTER_CODE
    );

    const newOption = productBadgeFilter?.options.find(
        ({ code }) => code === NEW_OPTION_CODE
    );

    if (newOption) {
        filters[PRODUCT_BADGE_FILTER_CODE] = {
            ...productBadgeFilter,
            options: [newOption],
            selected: newOption.selected,
        };
    } else {
        filters[PRODUCT_BADGE_FILTER_CODE] = {
            ...productBadgeFilter,
            options: [],
            selected: false,
        };
    }

    const occasionOption = productBadgeFilter?.options.find(
        ({ code }) => code === OCCASION_OPTION_CODE
    );

    if (occasionOption) {
        filters[DISCOUNT_FILTER_CODE] = {
            url: productBadgeFilter.url,
            label: productBadgeFilter.label,
            type: TOGGLE,
            code: DISCOUNT_FILTER_CODE,
            option: occasionOption,
            selected: occasionOption.selected,
        };
    }

    const localeHyphen = convertLocaleToIso(locale);

    return Object.fromEntries(
        Object.entries(filters).map(([key, filter]) => {
            if (!filter.options?.length) {
                return [key, filter];
            }

            filter.options = filter.options.map(option => ({
                ...option,
                productCountFormatted: formatNumber(
                    option.product_count,
                    localeHyphen
                ),
            }));

            return [key, filter];
        })
    );
}
