import Vuex from 'vuex';
import { state, actions, mutations } from '@store/index.js';
import { LOCALE, STORE_VIEW } from '@jest-helpers/config';

import { localeConfigBuilder } from '@assets/locale-config';
import { categoriesMock, tree } from '@jest-mocks/categoryNavigationTree';

import {
    state as stateLayout,
    actions as actionsLayout,
    mutations as mutationsLayout,
} from '@store/layout';

import {
    state as stateCustomer,
    actions as actionsCustomer,
    getters as gettersCustomer,
    mutations as mutationsCustomer,
} from '@store/customer';

import {
    state as stateMessages,
    actions as actionsMessages,
    mutations as mutationsMessages,
} from '@store/messages';

import {
    state as stateCmsBlock,
    actions as actionsCmsBlock,
    getters as gettersCmsBlock,
    mutations as mutationsCmsBlock,
} from '@store/cmsBlock';

import {
    state as stateNavigation,
    actions as actionsNavigation,
    getters as gettersNavigation,
    mutations as mutationsNavigation,
} from '@store/navigation';

import {
    state as stateConfig,
    actions as actionsConfig,
    getters as gettersConfig,
    mutations as mutationsConfig,
} from '@store/config';

import {
    state as stateCategory,
    actions as actionsCategory,
    getters as gettersCategory,
    mutations as mutationsCategory,
} from '@store/category';

import {
    state as stateRecentlyViewedProducts,
    actions as actionsRecentlyViewedProducts,
    mutations as mutationsRecentlyViewedProducts,
} from '@store/recentlyViewedProducts';

import {
    state as stateCart,
    actions as actionsCart,
    getters as gettersCart,
    mutations as mutationsCart,
} from '@store/cart';

import {
    state as stateSort,
    actions as actionsSort,
    mutations as mutationsSort,
} from '@store/sort';

import {
    state as stateGdpr,
    actions as actionsGdpr,
    mutations as mutationsGdpr,
} from '@store/gdpr';

import {
    state as stateAvatar,
    actions as actionsAvatar,
    mutations as mutationsAvatar,
    getters as gettersAvatar,
} from '@store/avatar';

import {
    state as stateConsents,
    actions as actionsConsents,
    mutations as mutationsConsents,
} from '@store/consents';

import headerStore from '@header/store';

export const createStore = (
    orderCode = '',
    locale = LOCALE,
    categories = categoriesMock,
    productsCount = 123
) => {
    return {
        state,
        actions,
        mutations,
        modules: {
            layout: {
                namespaced: true,
                state: stateLayout,
                actions: actionsLayout,
                mutations: mutationsLayout,
            },
            customer: {
                namespaced: true,
                state: stateCustomer,
                getters: gettersCustomer,
                actions: actionsCustomer,
                mutations: mutationsCustomer,
            },
            messages: {
                namespaced: true,
                state: stateMessages,
                actions: actionsMessages,
                mutations: mutationsMessages,
            },
            cmsBlock: {
                namespaced: true,
                state: stateCmsBlock,
                getters: gettersCmsBlock,
                actions: actionsCmsBlock,
                mutations: mutationsCmsBlock,
            },
            config: {
                namespaced: true,
                state: () => {
                    const config = stateConfig();

                    return {
                        ...config,
                        storeViews: [STORE_VIEW],
                        storeView: {
                            ...STORE_VIEW,
                            locale,
                        },
                        localeConfig: localeConfigBuilder(locale),
                    };
                },
                getters: gettersConfig,
                actions: actionsConfig,
                mutations: mutationsConfig,
            },
            navigation: {
                namespaced: true,
                state: stateNavigation,
                getters: gettersNavigation,
                actions: actionsNavigation,
                mutations: mutationsNavigation,
            },
            category: {
                namespaced: true,
                state: () => {
                    const category = stateCategory();

                    return {
                        ...category,
                        query: '',
                        navigation: {
                            ...category.navigation,
                            categories,
                            tree: tree(productsCount),
                        },
                    };
                },
                getters: gettersCategory,

                actions: actionsCategory,
                mutations: mutationsCategory,
            },
            filters: {
                namespaced: true,
                state: () => ({
                    availableFilters: {},
                    urlFilters: {},
                }),
            },
            recentlyViewedProducts: {
                namespaced: true,
                state: stateRecentlyViewedProducts,
                actions: actionsRecentlyViewedProducts,
                mutations: mutationsRecentlyViewedProducts,
            },
            cart: {
                namespaced: true,
                state: stateCart,
                getters: gettersCart,
                actions: actionsCart,
                mutations: mutationsCart,
            },
            sort: {
                namespaced: true,
                state: () => {
                    const sort = stateSort();

                    return {
                        ...sort,
                        orderCode,
                    };
                },
                actions: actionsSort,
                mutations: mutationsSort,
            },
            gdpr: {
                namespaced: true,
                state: stateGdpr,
                actions: actionsGdpr,
                mutations: mutationsGdpr,
            },
            avatar: {
                namespaced: true,
                state: stateAvatar,
                actions: actionsAvatar,
                mutations: mutationsAvatar,
                getters: gettersAvatar,
            },
            consents: {
                namespaced: true,
                state: stateConsents,
                actions: actionsConsents,
                mutations: mutationsConsents,
            },
            header: headerStore,
        },
    };
};

export const initStore = () => new Vuex.Store(createStore());
