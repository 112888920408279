<template>
    <div class="main">
        <template v-if="isWebViewMobile">
            <main>
                <nuxt />
            </main>
        </template>

        <template v-else>
            <MarketingBarDataProvider />
            <HeaderWrapper />
            <main>
                <nuxt />
            </main>
            <Messages />
            <NewsletterSectionWrapper />
            <ChatWrapper />
            <BaseFooter />
            <ProductExitModalWrapper
                v-if="showProductExitModal"
                :is-mobile="isMobile"
            />
            <GlobalModals />
        </template>
        <SmartBannerWrapper />
    </div>
</template>

<script>
import { mapState, createNamespacedHelpers } from 'vuex';

import {
    CHECKOUT_PAGE_NAME,
    CHECKOUT_CART_PAGE_NAME,
    CHECKOUT_SUCCESS_PAGE_NAME,
    CHECKOUT_REPAYMENT_NAME,
    PRODUCT_PAGE_NAME,
} from '@router/names';
import {
    SEARCH_RESULTS_PAGE_NAME,
    CATALOG_PAGE_NAME,
} from '@search/routing/names';

import ChatWrapper from '@molecules/ChatWrapper/ChatWrapper';
import Messages from '@molecules/Messages/Messages';
import NewsletterSectionWrapper from '@molecules/NewsletterSectionWrapper/NewsletterSectionWrapper';
import SmartBannerWrapper from '@molecules/SmartBanner/SmartBannerWrapper';

import BaseFooter from '@organisms/BaseFooter/BaseFooter';
import GlobalModals from '@organisms/GlobalModals/GlobalModals';
import HeaderWrapper from '@header-organisms/HeaderWrapper/HeaderWrapper';
import MarketingBarDataProvider from '@marketing-bar-components/organisms/MarketingBarDataProvider/MarketingBarDataProvider';

import BaseLayout from './mixins/BaseLayout';
import MobileWebView from './mixins/MobileWebView';

const HIDE_EXIT_POPUP_ROUTE_NAMES = [
    CHECKOUT_PAGE_NAME,
    CHECKOUT_CART_PAGE_NAME,
    CHECKOUT_SUCCESS_PAGE_NAME,
    CHECKOUT_REPAYMENT_NAME,
    PRODUCT_PAGE_NAME,
    SEARCH_RESULTS_PAGE_NAME,
    CATALOG_PAGE_NAME,
];

const { mapActions: mapSearchActions } = createNamespacedHelpers('search');

export default {
    name: 'DefaultLayout',

    components: {
        Messages,
        NewsletterSectionWrapper,
        BaseFooter,
        ChatWrapper,
        GlobalModals,
        MarketingBarDataProvider,
        HeaderWrapper,
        SmartBannerWrapper,
        ProductExitModalWrapper: () => ({
            component: import(
                // eslint-disable-next-line max-len
                /* webpackChunkName: "product-exit-modal-wrapper" */ '@organisms/ProductExitModalWrapper/ProductExitModalWrapper'
            ),
        }),
    },

    mixins: [BaseLayout, MobileWebView],

    computed: {
        ...mapState(['isMobile']),

        showProductExitModal() {
            return !HIDE_EXIT_POPUP_ROUTE_NAMES.includes(this.$route.name);
        },
    },

    methods: {
        ...mapSearchActions(['setIsSearchModalOpen']),
    },
};
</script>
