import {
    MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
    MEGATRON_CLIENT_ENDPOINT_PARAMETER_SMAUG,
} from '@configs/megatron-client';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

const getErrorTags = action => ({
    [ERROR_ACTION_TAG_NAME]: `services.payments.klarna.${action}`,
});

export default (megatronClient, $errorHandler) => ({
    async createKlarnaSession(cartId) {
        const ERROR_TAGS = getErrorTags('createKlarnaSession');

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/createKlarnaSession',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload: {
                        cartId,
                    },
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return data?.createKlarnaSession?.clientToken;
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    cartId,
                    data,
                    error,
                    status,
                }
            );

            return null;
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, {
                cartId,
            });

            return null;
        }
    },

    async extraMerchantData(customerUuid) {
        try {
            return await megatronClient.get(
                `v1/klarna/customer/${customerUuid}/extra-merchant-data`,
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_SMAUG,
                    context: {
                        useAuthorization: false,
                    },
                }
            );
        } catch (error) {
            $errorHandler.captureError(
                error,
                {
                    [ERROR_ACTION_TAG_NAME]:
                        'services.payments.klarna.extra-merchant-data',
                },
                {
                    payload: {
                        customerUuid,
                    },
                }
            );
        }
    },
});
