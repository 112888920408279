import { isAllowed } from '@modules/page-builder/domain-whitelist';

export default context => {
    if (process.client) {
        return;
    }

    const {
        req: { headers },
        $config: { imaginatorUrl },
    } = context;

    const additionalHeaders = {
        'Content-Security-Policy': 'upgrade-insecure-requests',
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'no-referrer-when-downgrade',
        'Permissions-Policy':
            // eslint-disable-next-line max-len
            'accelerometer=(), camera=(), gyroscope=(), magnetometer=(), microphone=(), payment=(self google), usb=(), midi=()',
    };

    const refererHeader = headers?.referer || '';
    const linkHeader = headers?.link || '';

    if (!isAllowed(refererHeader)) {
        additionalHeaders['X-Frame-Options'] = 'DENY';
    }

    const linkHeaders = linkHeader ? [linkHeader] : [];

    if (imaginatorUrl) {
        linkHeaders.push(`<${imaginatorUrl}>; rel=preconnect`);
    }

    context.res.set('link', linkHeaders);
    context.res.set(additionalHeaders);
};
