<template>
    <div>
        <CheckoutHeaderV2 :current-step="2" :is-checkout-header="true" />

        <main class="checkout-wrapper">
            <nuxt />
        </main>

        <Messages />
        <CheckoutFooter />
        <ChatWrapper />
        <GlobalModals />
        <SmartBannerWrapper />
    </div>
</template>

<script>
import ChatWrapper from '@molecules/ChatWrapper/ChatWrapper';
import Messages from '@molecules/Messages/Messages';
import SmartBannerWrapper from '@molecules/SmartBanner/SmartBannerWrapper';

import GlobalModals from '@organisms/GlobalModals/GlobalModals';
import CheckoutHeaderV2 from '@organisms/CheckoutHeader/CheckoutHeaderV2';
import CheckoutFooter from '@organisms/CheckoutFooter/CheckoutFooter';

import BaseLayout from './mixins/BaseLayout';

export default {
    name: 'CheckoutLayoutV2',

    components: {
        CheckoutHeaderV2,
        Messages,
        CheckoutFooter,
        ChatWrapper,
        GlobalModals,
        SmartBannerWrapper,
    },

    mixins: [BaseLayout],
};
</script>

<style lang="scss" scoped>
.checkout-wrapper {
    @apply bg-ui-swap-gray100;
}
</style>
