<template>
    <div
        :class="{
            'second-stage': stickySecondStage && isMobile,
        }"
        class="header-wrapper"
    >
        <header class="header">
            <HeaderTopBar v-if="!isMobile" class="desktop-top-bar" />

            <HeaderMainBar
                :class="{
                    'hidden-state': stickySecondStage && isMobile,
                }"
                class="main-bar"
            />

            <div class="search-button-wrapper">
                <HeaderSearchButton />
            </div>

            <HeaderNavigationBar
                v-if="!isMobile"
                :class="{
                    'hidden-state': stickySecondStage,
                }"
                class="nav-bar"
            />

            <Divider class="header-divider" />
        </header>

        <Scrim v-show="isDesktopMenuOpen" class="backdrop" />
    </div>
</template>

<script>
import { createNamespacedHelpers, mapState } from 'vuex';

import throttle from 'lodash.throttle';

import { STICKY_HEADER_WITH_FILTERS_AB_TEST_COOKIE } from '@search/assets/ab-tests';

import HeaderTopBar from '@header-organisms/HeaderTopBar/HeaderTopBar';
import HeaderMainBar from '@header-organisms/HeaderMainBar/HeaderMainBar';
import HeaderSearchButton from '@header-organisms/HeaderSearchButton/HeaderSearchButton';
import HeaderNavigationBar from '@header-organisms/HeaderNavigationBar/HeaderNavigationBar';

import { Divider } from '@eobuwie-ui/components/Divider/v1';
import { Scrim } from '@eobuwie-ui/components/Scrim/v1';

const { mapState: mapNavigationState } = createNamespacedHelpers('navigation');
const { mapActions: mapAutocompleteActions } = createNamespacedHelpers(
    'header/autocomplete'
);

const SCROLL_POSITION_MARGIN = 100;

export default {
    name: 'HeaderWrapper',

    components: {
        HeaderTopBar,
        HeaderMainBar,
        HeaderSearchButton,
        HeaderNavigationBar,
        Divider,
        Scrim,
    },

    data() {
        return {
            frameId: null,
            lastScrollPosition: 0,
            stickySecondStage: false,
        };
    },

    computed: {
        ...mapState(['isMobile']),
        ...mapNavigationState(['isDesktopMenuOpen']),

        isStickyAbTestOn() {
            return (
                this.$abTests.getVariant(
                    STICKY_HEADER_WITH_FILTERS_AB_TEST_COOKIE
                ) === 'on'
            );
        },
    },

    beforeMount() {
        if (this.isStickyAbTestOn) {
            return;
        }

        this.handleScrollDelayed = throttle(this.handleScroll, 50);
        window.addEventListener('scroll', this.handleScrollDelayed);
    },

    mounted() {
        this.checkIfIsNewAutocomplete();
    },

    beforeDestroy() {
        if (this.isStickyAbTestOn) {
            return;
        }

        this.handleScrollDelayed.cancel();
        window.removeEventListener('scroll', this.handleScrollDelayed);
    },

    methods: {
        ...mapAutocompleteActions(['checkIfIsNewAutocomplete']),
        handleScroll() {
            if (this.frameId) {
                return;
            }

            this.frameId = window.requestAnimationFrame(() => {
                this.onScroll();
                this.frameId = null;
            });
        },

        onScroll() {
            const scrollPosition = window.scrollY;

            if (this.lastScrollPosition === 0) {
                this.lastScrollPosition = scrollPosition;

                return;
            }

            if (
                scrollPosition <
                this.lastScrollPosition - SCROLL_POSITION_MARGIN
            ) {
                this.stickySecondStage = false;
            }

            if (
                scrollPosition >=
                this.lastScrollPosition + SCROLL_POSITION_MARGIN
            ) {
                this.stickySecondStage = scrollPosition >= 200;
            }

            if (
                (!this.stickySecondStage &&
                    scrollPosition < this.lastScrollPosition) ||
                (this.stickySecondStage &&
                    scrollPosition > this.lastScrollPosition)
            ) {
                this.lastScrollPosition = scrollPosition;
            }
        },
    },
};
</script>

<style scoped lang="scss">
.header-wrapper {
    transition: 0.3s;
    @apply sticky top-0 z-4;

    &.second-stage {
        @apply top-[-48px];
    }

    .hidden-state {
        @apply opacity-0;
        @apply pointer-events-none;
    }

    .header {
        @apply relative bg-ui-container-default-default;
        @apply z-4;
        @apply max-h-[160px];
    }

    .desktop-top-bar {
        @apply hidden;
    }

    .search-button-wrapper {
        @apply px-ui-4;
    }

    .main-bar,
    .nav-bar {
        transition: 0.3s;
        transition-delay: opacity 0.2s;
    }

    .nav-bar {
        @apply hidden;
    }

    .header-divider {
        @apply mt-ui-4;
    }

    .backdrop {
        @apply z-2 fixed;
    }

    @screen lg {
        @apply top-[-48px];

        .header {
            @apply max-h-none;
        }

        .desktop-top-bar {
            @apply block;
        }

        .search-button-wrapper {
            @apply hidden;
        }

        .nav-bar {
            @apply block;

            &.hidden-state {
                @apply h-ui-6;
            }
        }

        .header-divider {
            @apply mt-0;
        }
    }
}
</style>
