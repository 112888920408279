<template>
    <HeaderTooltip
        :is-enabled="isTooltipEnabled"
        class="wishlist-tooltip"
        @mouseenter.native="sendEvent()"
    >
        <HeaderActionButton
            :icon="Favourite"
            :path="wishlistPath"
            :digit="wishlistCount"
            :label="$t('Wishlist')"
            :analytics-click-event="HEADER_WISHLIST_CLICK"
        />

        <template #tooltipContent>
            <HeaderTooltipContent
                :illustration="GreenHeart"
                :heading="headingCopy"
                :description="
                    $t(
                        // eslint-disable-next-line max-len
                        'We will let you know about price cuts on your favorite products, novelties and current promotions.'
                    )
                "
                :primary-button-text="$t('Sign up')"
                @primary-button-click="openNewsletterModal()"
            />
        </template>
    </HeaderTooltip>
</template>

<script>
import { createNamespacedHelpers, mapState } from 'vuex';

import {
    MODAL_NEWSLETTER_NAME,
    SHOULD_OPEN_MODAL_ON_CLICK,
    MODAL_NEWSLETTER_RELEASER,
    NEWSLETTER_RELEASER_WISHLIST_HOVER,
    GDPR_LAYOUT_HANDLE,
} from '@configs/modals';
import { WISHLIST } from '@configs/gdpr';
import { isNewsletterCopyTestOn } from '@configs/newsletter';

import { WISHLIST_PAGE } from '@router/paths';

import { HEADER_WISHLIST_CLICK } from '@analytics-types/Events';
import { MODULE_NAME } from '@analytics-modules/client/meta';
import { NEWSLETTER_TRIGGER_VIEW } from '@analytics-modules/client/types/Events';
import { WISHLIST_HOVER } from '@analytics-modules/client/types/Labels';

import HeaderTooltip from '@header-atoms/HeaderTooltip/HeaderTooltip';
import HeaderActionButton from '@header-atoms/HeaderActionButton/HeaderActionButton';

import { Favourite } from '@eobuwie-ui/icons/v1/other';

import GreenHeart from '@header/illustrations/green-heart.svg?inline';

const {
    mapState: mapCustomerState,
    mapGetters: mapCustomerGetters,
} = createNamespacedHelpers('customer');

export default {
    name: 'WishlistTooltip',

    components: {
        HeaderActionButton,
        HeaderTooltip,
        HeaderTooltipContent: () => ({
            component: import(
                /* webpackChunkName: "header-tooltip-content" */
                '@header-atoms/HeaderTooltipContent/HeaderTooltipContent'
            ),
        }),
    },

    computed: {
        ...mapCustomerGetters(['wishlistCount', 'isLoggedIn']),
        ...mapCustomerState(['isCustomerSubscribedToNewsletter']),
        ...mapState(['isMobile']),

        isTooltipEnabled() {
            return (
                this.wishlistCount > 0 &&
                this.isLoggedIn &&
                !this.isCustomerSubscribedToNewsletter
            );
        },

        headingCopy() {
            const abTestCopy =
                // eslint-disable-next-line max-len
                'I want to subscribe to the Newsletter and receive a 10€ discount on subsequent purchases';

            const regularCopy =
                // eslint-disable-next-line max-len
                'We will let you know about price cuts on your favorite products, news and current promotions.';

            return this.$t(
                isNewsletterCopyTestOn(this.$abTests) ? abTestCopy : regularCopy
            );
        },
    },

    beforeCreate() {
        this.wishlistPath = `/${WISHLIST_PAGE}`;
        this.Favourite = Favourite;
        this.HEADER_WISHLIST_CLICK = HEADER_WISHLIST_CLICK;
        this.GreenHeart = GreenHeart;
    },

    methods: {
        openNewsletterModal() {
            this.$modals.open(MODAL_NEWSLETTER_NAME, {
                [SHOULD_OPEN_MODAL_ON_CLICK]: true,
                [MODAL_NEWSLETTER_RELEASER]: NEWSLETTER_RELEASER_WISHLIST_HOVER,
                [GDPR_LAYOUT_HANDLE]: WISHLIST,
            });
        },

        sendEvent() {
            this.$analytics.moduleEmit(MODULE_NAME, NEWSLETTER_TRIGGER_VIEW, {
                eventLabel: WISHLIST_HOVER,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.wishlist-tooltip {
    .tooltip-content {
        @apply w-[320px] p-ui-4 border-ui-container-stroke-100-default border-ui-1 border-solid;
        @apply bg-ui-background-primary-default;

        .heading {
            @apply leading-ui-24;
        }

        .text {
            @apply text-ui-12 leading-ui-18 pb-ui-4 pt-ui-1;
        }
    }
}
</style>
