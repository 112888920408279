import { getAvatarIdFromCookies, getAvatarIdFromStore } from '@assets/avatar';

export const CHANNEL_HEADER = { channel: 'web' };
export const APP_NAME_HEADER = { 'x-app-name': 'eobuwie-pwa' };
export const PLATFORM_HEADER = { 'x-platform': 'web' };

export const getAppVersionHeader = version => ({ 'x-app-version': version });

const X_INSTANCE_ID_HEADER_NAME = 'x-instance-id';

export const getInstanceIdHeader = ($cookies, $store) => {
    const avatarId =
        getAvatarIdFromCookies($cookies) || getAvatarIdFromStore($store);

    if (!avatarId) {
        return {};
    }

    return {
        [X_INSTANCE_ID_HEADER_NAME]: avatarId,
    };
};
