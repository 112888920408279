const EOBUWIE_SUFFIX_PATH = 'eobuwie/web';

export const SYNERISE_SUFFIX_PATH = 'rest/synerise';
export const SEARCH_SUFFIX_PATH = 'rest/search/eobuwie';
export const OMNIBUS_SUFFIX_PATH = 'omnibus/web';
export const MAGENTO_SUFFIX_PATH = 'rest/magento/eobuwie';
export const MODIVO_SMAUG_SUFFIX_PATH = 'rest/smaug';

export const createMegatronEndpointUrl = (
    baseUrl,
    suffix = EOBUWIE_SUFFIX_PATH
) => `${[baseUrl, suffix].join('/')}`;
