export const SHOW_LAST_ITEMS_INFO_QUANTITY = 3;

export const CHECKOUT_METHODS_ICON_SIZE = {
    width: 40,
    height: 20,
};

export const MOBILE_CHECKOUT_SUCCESS_METHODS_ICON_SIZE = {
    width: 48,
    height: 24,
};

export const DESKTOP_CHECKOUT_SUCCESS_METHODS_ICON_SIZE = {
    ...CHECKOUT_METHODS_ICON_SIZE,
};

export const TYP_BANNER = 'eob_typ_banner';
export const CART_BANNER = 'eob_cart_banner';

export const LOCALES_WITH_HIDDEN_HELP_CENTER_LINK = [
    'cs_CZ',
    'de_DE',
    'de_CH',
    'fr_CH',
    'hu_HU',
    'it_CH',
    'it_IT',
];

export const DEFAULT_CHECKOUT_LAYOUT = 'checkoutV2';
export const OLD_CHECKOUT_LAYOUT = 'checkout';

export const CHECKOUT_LAYOUTS_MAP = {
    V5: OLD_CHECKOUT_LAYOUT,
    V6: OLD_CHECKOUT_LAYOUT,
    V8: DEFAULT_CHECKOUT_LAYOUT,
};
