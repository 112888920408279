export default () => ({
    cartId: null,
    cartData: null,
    complexProductDataItems: [],
    isCartDataValid: false,
    isCartDataLoading: true,
    shouldFetchPaymentAndShippingAfterLogin: false,
    loaderText: '',
    padData: null,
    selectedPaymentType: '',
    isFetchingCartWithPostcode: false,
    giftCardData: null,
    isAddToCartPending: false,
});
