var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-wrapper",class:{
        'second-stage': _vm.stickySecondStage && _vm.isMobile,
    }},[_c('header',{staticClass:"header"},[(!_vm.isMobile)?_c('HeaderTopBar',{staticClass:"desktop-top-bar"}):_vm._e(),_vm._v(" "),_c('HeaderMainBar',{staticClass:"main-bar",class:{
                'hidden-state': _vm.stickySecondStage && _vm.isMobile,
            }}),_vm._v(" "),_c('div',{staticClass:"search-button-wrapper"},[_c('HeaderSearchButton')],1),_vm._v(" "),(!_vm.isMobile)?_c('HeaderNavigationBar',{staticClass:"nav-bar",class:{
                'hidden-state': _vm.stickySecondStage,
            }}):_vm._e(),_vm._v(" "),_c('Divider',{staticClass:"header-divider"})],1),_vm._v(" "),_c('Scrim',{directives:[{name:"show",rawName:"v-show",value:(_vm.isDesktopMenuOpen),expression:"isDesktopMenuOpen"}],staticClass:"backdrop"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }