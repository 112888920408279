import { types } from './mutations';

export default {
    setAgreementsData({ commit }, { forViewId, agreementData }) {
        commit(types.SET_AGREEMENTS_DATA, { forViewId, agreementData });
    },

    setFetching({ commit }, isFetching) {
        commit(types.SET_FETCHING, isFetching);
    },

    async getGdprData(
        { dispatch },
        { viewId, forViewId, customerEmail = null }
    ) {
        dispatch('setFetching', true);

        const agreementData = await this.app.$services.gdpr.getGdprAgreementsData(
            viewId,
            customerEmail
        );

        if (agreementData?.length) {
            dispatch('setAgreementsData', {
                forViewId,
                agreementData,
            });

            dispatch('setFetching', false);

            return true;
        }

        if (process.client) {
            dispatch(
                'messages/addErrorMessage',
                {
                    text: this.app.i18n.t('Fetching GDPR data error occured'),
                },
                { root: true }
            );
        }

        dispatch('setFetching', false);

        return false;
    },

    async setGdprData({ dispatch }, gdprData) {
        const wasCreated = await this.app.$services.gdpr.createGdprAgreementDecision(
            gdprData
        );

        if (!wasCreated) {
            dispatch(
                'messages/addErrorMessage',
                { text: this.app.i18n.t('Change of GDPR data failed') },
                { root: true }
            );
        }

        return wasCreated;
    },
};
