<template>
    <ProductExitModal
        v-if="load"
        :is-open="isOpen"
        @close="closeModal()"
        @error="closeModal()"
        @loaded="onProductsLoaded()"
    />
</template>

<script>
import {
    EXIT_MODAL_VISITED_PRODUCTS_KEY,
    EXIT_MODAL_SHOWN_KEY,
    STORAGE_TYPE_SESSION,
} from '@configs/storage';

const SHOW_MODAL_AFTER_NTH_PRODUCT = 2;

export default {
    name: 'ProductExitModalWrapper',

    components: {
        ProductExitModal: () => ({
            component: import(
                // eslint-disable-next-line max-len
                /* webpackChunkName: "product-exit-modal" */ '@organisms/ProductExitModal/ProductExitModal'
            ),
        }),
    },

    props: {
        isMobile: {
            type: Boolean,
            required: true,
        },

        sku: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            isOpen: false,
            isProductsLoaded: false,
            load: false,
        };
    },

    watch: {
        isMobile() {
            if (!this.wasModalShownBefore()) {
                this.listenIfDesktop(this.visitedProducts().length);
            }
        },
    },

    mounted() {
        if (this.wasModalShownBefore()) {
            return;
        }

        const updatedVisitedProducts = this.visitedProducts().filter(
            productSku => productSku !== this.sku
        );

        if (
            this.sku &&
            updatedVisitedProducts.length < SHOW_MODAL_AFTER_NTH_PRODUCT
        ) {
            updatedVisitedProducts.push(this.sku);

            this.setStorageItem(
                EXIT_MODAL_VISITED_PRODUCTS_KEY,
                updatedVisitedProducts
            );
        }

        this.listenIfDesktop(updatedVisitedProducts.length);
    },

    beforeDestroy() {
        document.removeEventListener('mouseout', this.mouseOutHandler);
    },

    methods: {
        closeModal() {
            this.isOpen = false;
        },

        mouseOutHandler({ toElement, relatedTarget, clientY }) {
            if (!toElement && !relatedTarget && clientY <= 10) {
                document.removeEventListener('mouseout', this.mouseOutHandler);

                this.isOpen = true;
                this.checkIfUserSawProducts();
            }
        },

        listenIfDesktop(visitedProductsCount) {
            if (this.isMobile) {
                this.load = false;
                document.removeEventListener('mouseout', this.mouseOutHandler);
            } else if (
                !this.load &&
                visitedProductsCount >= SHOW_MODAL_AFTER_NTH_PRODUCT
            ) {
                this.load = true;
                document.addEventListener('mouseout', this.mouseOutHandler);
            }
        },

        wasModalShownBefore() {
            return this.getStorageItem(EXIT_MODAL_SHOWN_KEY) === true;
        },

        visitedProducts() {
            return this.getStorageItem(EXIT_MODAL_VISITED_PRODUCTS_KEY) || [];
        },

        getStorageItem(key) {
            return this.$storage.getItem(key, STORAGE_TYPE_SESSION);
        },

        setStorageItem(key, value) {
            return this.$storage.setItem(
                key,
                value,
                null,
                STORAGE_TYPE_SESSION
            );
        },

        onProductsLoaded() {
            this.isProductsLoaded = true;
            this.checkIfUserSawProducts();
        },

        checkIfUserSawProducts() {
            if (this.isOpen && this.isProductsLoaded) {
                this.setStorageItem(EXIT_MODAL_SHOWN_KEY, true);
            }
        },
    },
};
</script>
