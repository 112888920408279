import { v4 as uuidv4 } from 'uuid';

export const TOPICS = {
    LOYALTY_CLUB_SIGN_IN: 'LOYALTY_CLUB_SIGN_IN',
};

export default class PubSub {
    constructor() {
        this.subscribers = {
            [TOPICS.LOYALTY_CLUB_SIGN_IN]: {},
        };
    }

    publish(topic, payload = {}) {
        if (!this.subscribers[topic]) {
            return;
        }

        Object.values(this.subscribers[topic]).forEach(subscriber =>
            subscriber(payload)
        );
    }

    subscribe(topic, subscriber) {
        if (!this.subscribers[topic]) {
            return null;
        }

        const subscriberId = uuidv4();

        this.subscribers[topic][subscriberId] = subscriber;

        return subscriberId;
    }

    unsubscribe(subscriberId) {
        if (!subscriberId) {
            return;
        }

        Object.values(this.subscribers).forEach(
            subscribers => delete subscribers[subscriberId]
        );
    }
}
