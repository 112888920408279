export const IMAGE_TYPE_BRAND_154w_66h = 'eob_brand_154w_66h';
const IMAGE_TYPE_BRAND_154w_66h_WIDTH = 154;
const IMAGE_TYPE_BRAND_154w_66h_HEIGHT = 66;

export const IMAGE_TYPE_BRAND_312w_132h = 'eob_brand_312w_132h';
const IMAGE_TYPE_BRAND_312w_132h_WIDTH = 312;
const IMAGE_TYPE_BRAND_312w_132h_HEIGHT = 132;

export const IMAGE_TYPE_SVG = 'eob_svg';
const IMAGE_TYPE_SVG_WIDTH = '';
const IMAGE_TYPE_SVG_HEIGHT = 25;

export const IMAGE_TYPE_PRODUCT_64w_64h = 'eob_product_64w_64h';
const IMAGE_TYPE_PRODUCT_64w_64h_WIDTH = 64;
const IMAGE_TYPE_PRODUCT_64w_64h_HEIGHT = 64;

export const IMAGE_TYPE_PRODUCT_128w_128h = 'eob_product_128w_128h';
const IMAGE_TYPE_PRODUCT_128w_128h_WIDTH = 128;
const IMAGE_TYPE_PRODUCT_128w_128h_HEIGHT = 128;

export const IMAGE_TYPE_PRODUCT_152w_152h = 'eob_product_152w_152h';
const IMAGE_TYPE_PRODUCT_152w_152h_WIDTH = 152;
const IMAGE_TYPE_PRODUCT_152w_152h_HEIGHT = 152;

export const IMAGE_TYPE_PRODUCT_256w_256h = 'eob_product_256w_256h';
const IMAGE_TYPE_PRODUCT_256w_256h_WIDTH = 256;
const IMAGE_TYPE_PRODUCT_256w_256h_HEIGHT = 256;

export const IMAGE_TYPE_PRODUCT_512w_512h = 'eob_product_512w_512h';
const IMAGE_TYPE_PRODUCT_512w_512h_WIDTH = 512;
const IMAGE_TYPE_PRODUCT_512w_512h_HEIGHT = 512;

export const IMAGE_TYPE_PRODUCT_132w_176h = 'eob_product_132w_176h';
const IMAGE_TYPE_PRODUCT_132w_176h_WIDTH = 132;
const IMAGE_TYPE_PRODUCT_132w_176h_HEIGHT = 176;

export const IMAGE_TYPE_PRODUCT_528w_704h = 'eob_product_528w_704h';
const IMAGE_TYPE_PRODUCT_528w_704h_WIDTH = 528;
const IMAGE_TYPE_PRODUCT_528w_704h_HEIGHT = 704;

export const IMAGE_TYPE_PRODUCT_660w_880h = 'eob_product_660w_880h';
const IMAGE_TYPE_PRODUCT_660w_880h_WIDTH = 660;
const IMAGE_TYPE_PRODUCT_660w_880h_HEIGHT = 880;

export const IMAGE_TYPE_PRODUCT_1800w_2400h = 'eob_product_1800w_2400h';
const IMAGE_TYPE_PRODUCT_1800w_2400h_WIDTH = 1800;
const IMAGE_TYPE_PRODUCT_1800w_2400h_HEIGHT = 2400;

export const IMAGE_TYPE_DISPLAY_768w_457h = 'eob_marketing_display_768w_457h';
const IMAGE_TYPE_DISPLAY_768w_457h_WIDTH = 768;
const IMAGE_TYPE_DISPLAY_768w_457h_HEIGHT = 457;

export const IMAGE_TYPE_DISPLAY_1325w_250h = 'eob_marketing_display_1325w_250h';
const IMAGE_TYPE_DISPLAY_1325w_250h_WIDTH = 1325;
const IMAGE_TYPE_DISPLAY_1325w_250h_HEIGHT = 250;

export const IMAGE_TYPE_MARKETING_BANNER_1532w_450h =
    'eob_marketing_banner_1532w_450h';
const IMAGE_TYPE_MARKETING_BANNER_1532w_450h_WIDTH = 1532;
const IMAGE_TYPE_MARKETING_BANNER_1532w_450h_HEIGHT = 450;

export const IMAGE_TYPE_MARKETING_BANNER_1152w_450h =
    'eob_marketing_banner_1152w_450h';
const IMAGE_TYPE_MARKETING_BANNER_1152w_450h_WIDTH = 1152;
const IMAGE_TYPE_MARKETING_BANNER_1152w_450h_HEIGHT = 450;

export const IMAGE_TYPE_MARKETING_BANNER_767w_450h =
    'eob_marketing_banner_767w_450h';
const IMAGE_TYPE_MARKETING_BANNER_767w_450h_WIDTH = 767;
const IMAGE_TYPE_MARKETING_BANNER_767w_450h_HEIGHT = 450;

export const IMAGE_TYPE_MARKETING_BANNER_360w_450h =
    'eob_marketing_banner_360w_450h';
const IMAGE_TYPE_MARKETING_BANNER_360w_450h_WIDTH = 360;
const IMAGE_TYPE_MARKETING_BANNER_360w_450h_HEIGHT = 450;

export const IMAGE_TYPE_MARKETING_BANNER_450w_450h =
    'eob_marketing_banner_450w_450h';
const IMAGE_TYPE_MARKETING_BANNER_450w_450h_WIDTH = 450;
const IMAGE_TYPE_MARKETING_BANNER_450w_450h_HEIGHT = 450;

export const IMAGE_TYPE_MARKETING_BANNER_HONE_800w_800h =
    'eob_marketing_banner_hone_800w_800h';
const IMAGE_TYPE_MARKETING_BANNER_HONE_800w_800h_WIDTH = 800;
const IMAGE_TYPE_MARKETING_BANNER_HONE_800w_800h_HEIGHT = 800;

export const IMAGE_TYPE_MARKETING_BANNER_HONE_600w_400h =
    'eob_marketing_banner_hone_600w_400h';
const IMAGE_TYPE_MARKETING_BANNER_HONE_600w_400h_WIDTH = 600;
const IMAGE_TYPE_MARKETING_BANNER_HONE_600w_400h_HEIGHT = 400;

export const IMAGE_TYPE_MARKETING_CARD_256w_256h =
    'eob_marketing_card_256w_256h';
const IMAGE_TYPE_MARKETING_CARD_256w_256h_WIDTH = 256;
const IMAGE_TYPE_MARKETING_CARD_256w_256h_HEIGHT = 256;

export const IMAGE_TYPE_MARKETING_CARD_512w_512h =
    'eob_marketing_card_512w_512h';
const IMAGE_TYPE_MARKETING_CARD_512w_512h_WIDTH = 512;
const IMAGE_TYPE_MARKETING_CARD_512w_512h_HEIGHT = 512;

export const IMAGE_TYPE_MARKETING_CELEBRITY_492w_656h =
    'eob_marketing_celebrity_492w_656h';
const IMAGE_TYPE_MARKETING_CELEBRITY_492w_656h_WIDTH = 492;
const IMAGE_TYPE_MARKETING_CELEBRITY_492w_656h_HEIGHT = 656;

export const IMAGE_TYPE_MARKETING_CELEBRITY_324w_432h =
    'eob_marketing_celebrity_324w_432h';
const IMAGE_TYPE_MARKETING_CELEBRITY_324w_432h_WIDTH = 324;
const IMAGE_TYPE_MARKETING_CELEBRITY_324w_432h_HEIGHT = 432;

export const IMAGE_TYPE_MARKETING_AVATAR_32w_32h =
    'eob_marketing_avatar_32w_32h';
const IMAGE_TYPE_MARKETING_AVATAR_32w_32h_WIDTH = 32;
const IMAGE_TYPE_MARKETING_AVATAR_32w_32h_HEIGHT = 32;

export const IMAGE_TYPE_MARKETING_BRAND_115w_55h =
    'eob_marketing_brand_115w_55h';
const IMAGE_TYPE_MARKETING_BRAND_115w_55h_WIDTH = 115;
const IMAGE_TYPE_MARKETING_BRAND_115w_55h_HEIGHT = 55;

export const IMAGE_TYPE_MARKETING_BLOG_328w_328h =
    'eob_marketing_blog_328w_328h';
const IMAGE_TYPE_MARKETING_BLOG_328w_328h_WIDTH = 328;
const IMAGE_TYPE_MARKETING_BLOG_328w_328h_HEIGHT = 328;

export const IMAGE_TYPE_MARKETING_BLOG_492w_492h =
    'eob_marketing_blog_492w_492h';
const IMAGE_TYPE_MARKETING_BLOG_492w_492h_WIDTH = 492;
const IMAGE_TYPE_MARKETING_BLOG_492w_492h_HEIGHT = 492;

export const IMAGE_TYPE_MARKETING_BLOG_492w_656h =
    'eob_marketing_blog_492w_656h';
const IMAGE_TYPE_MARKETING_BLOG_492w_656h_WIDTH = 492;
const IMAGE_TYPE_MARKETING_BLOG_492w_656h_HEIGHT = 656;

export const IMAGE_TYPE_MARKETING_BLOG_786w_786h =
    'eob_marketing_blog_786w_786h';
const IMAGE_TYPE_MARKETING_BLOG_786w_786h_WIDTH = 786;
const IMAGE_TYPE_MARKETING_BLOG_786w_786h_HEIGHT = 786;

export const IMAGE_TYPE_MARKETING_BLOG_786w_1048h =
    'eob_marketing_blog_786w_1048h';
const IMAGE_TYPE_MARKETING_BLOG_786w_1048h_WIDTH = 786;
const IMAGE_TYPE_MARKETING_BLOG_786w_1048h_HEIGHT = 1048;

export const IMAGE_TYPE_MARKETING_INFLUENCER_243w_324h =
    'eob_marketing_influencer_243w_324h';
const IMAGE_TYPE_MARKETING_INFLUENCER_243w_324h_WIDTH = 243;
const IMAGE_TYPE_MARKETING_INFLUENCER_243w_324h_HEIGHT = 324;

export const IMAGE_TYPE_MARKETING_INFLUENCER_318w_424h =
    'eob_marketing_influencer_318w_424h';
const IMAGE_TYPE_MARKETING_INFLUENCER_318w_424h_WIDTH = 318;
const IMAGE_TYPE_MARKETING_INFLUENCER_318w_424h_HEIGHT = 424;

export const IMAGE_TYPE_MARKETING_INFLUENCER_513w_684h =
    'eob_marketing_influencer_513w_684h';
const IMAGE_TYPE_MARKETING_INFLUENCER_513w_684h_WIDTH = 513;
const IMAGE_TYPE_MARKETING_INFLUENCER_513w_684h_HEIGHT = 684;

export const IMAGE_TYPE_PROMO_CATEGORY_COVER_256w_528h =
    'eob_marketing_promo_category_cover_256w_528h';
const IMAGE_TYPE_PROMO_CATEGORY_COVER_256w_528h_WIDTH = 256;
const IMAGE_TYPE_PROMO_CATEGORY_COVER_256w_528h_HEIGHT = 528;

export const IMAGE_TYPE_PROMO_CATEGORY_164w_164h =
    'eob_marketing_promo_category_164w_164h';
const IMAGE_TYPE_PROMO_CATEGORY_164w_164h_WIDTH = 164;
const IMAGE_TYPE_PROMO_CATEGORY_164w_164h_HEIGHT = 164;

export const IMAGE_TYPE_MARKETING_VALUE_PROPOSITION_198w_140h =
    'eob_marketing_value_proposition_198w_140h';
const IMAGE_TYPE_MARKETING_VALUE_PROPOSITION_198w_140h_WIDTH = 198;
const IMAGE_TYPE_MARKETING_VALUE_PROPOSITION_198w_140h_HEIGHT = 140;

export const IMAGE_TYPE_MARKETING_BRANDS_SECTION_256w_106h =
    'eob_marketing_brands_section_256w_106h';
const IMAGE_TYPE_MARKETING_BRANDS_SECTION_256w_106h_WIDTH = 256;
const IMAGE_TYPE_MARKETING_BRANDS_SECTION_256w_106h_HEIGHT = 106;

export const IMAGE_TYPE_MAGENTO = 'mag';
const IMAGE_TYPE_MAGENTO_WIDTH = null;
const IMAGE_TYPE_MAGENTO_HEIGHT = null;

export const MAGENTO_IMAGE_TYPES = {
    [IMAGE_TYPE_MAGENTO]: {
        name: IMAGE_TYPE_MAGENTO,
        width: IMAGE_TYPE_MAGENTO_WIDTH,
        height: IMAGE_TYPE_MAGENTO_HEIGHT,
    },
};

export const PRODUCT_IMAGE_TYPES = {
    [IMAGE_TYPE_PRODUCT_64w_64h]: {
        name: IMAGE_TYPE_PRODUCT_64w_64h,
        width: IMAGE_TYPE_PRODUCT_64w_64h_WIDTH,
        height: IMAGE_TYPE_PRODUCT_64w_64h_HEIGHT,
    },
    [IMAGE_TYPE_PRODUCT_128w_128h]: {
        name: IMAGE_TYPE_PRODUCT_128w_128h,
        width: IMAGE_TYPE_PRODUCT_128w_128h_WIDTH,
        height: IMAGE_TYPE_PRODUCT_128w_128h_HEIGHT,
    },
    [IMAGE_TYPE_PRODUCT_152w_152h]: {
        name: IMAGE_TYPE_PRODUCT_152w_152h,
        width: IMAGE_TYPE_PRODUCT_152w_152h_WIDTH,
        height: IMAGE_TYPE_PRODUCT_152w_152h_HEIGHT,
    },
    [IMAGE_TYPE_PRODUCT_256w_256h]: {
        name: IMAGE_TYPE_PRODUCT_256w_256h,
        width: IMAGE_TYPE_PRODUCT_256w_256h_WIDTH,
        height: IMAGE_TYPE_PRODUCT_256w_256h_HEIGHT,
    },
    [IMAGE_TYPE_PRODUCT_512w_512h]: {
        name: IMAGE_TYPE_PRODUCT_512w_512h,
        width: IMAGE_TYPE_PRODUCT_512w_512h_WIDTH,
        height: IMAGE_TYPE_PRODUCT_512w_512h_HEIGHT,
    },
    [IMAGE_TYPE_PRODUCT_528w_704h]: {
        name: IMAGE_TYPE_PRODUCT_528w_704h,
        width: IMAGE_TYPE_PRODUCT_528w_704h_WIDTH,
        height: IMAGE_TYPE_PRODUCT_528w_704h_HEIGHT,
    },
    [IMAGE_TYPE_PRODUCT_132w_176h]: {
        name: IMAGE_TYPE_PRODUCT_132w_176h,
        width: IMAGE_TYPE_PRODUCT_132w_176h_WIDTH,
        height: IMAGE_TYPE_PRODUCT_132w_176h_HEIGHT,
    },
    [IMAGE_TYPE_PRODUCT_660w_880h]: {
        name: IMAGE_TYPE_PRODUCT_660w_880h,
        width: IMAGE_TYPE_PRODUCT_660w_880h_WIDTH,
        height: IMAGE_TYPE_PRODUCT_660w_880h_HEIGHT,
    },
    [IMAGE_TYPE_PRODUCT_1800w_2400h]: {
        name: IMAGE_TYPE_PRODUCT_1800w_2400h,
        width: IMAGE_TYPE_PRODUCT_1800w_2400h_WIDTH,
        height: IMAGE_TYPE_PRODUCT_1800w_2400h_HEIGHT,
    },
};

export const BRAND_IMAGE_TYPES = {
    [IMAGE_TYPE_BRAND_154w_66h]: {
        name: IMAGE_TYPE_BRAND_154w_66h,
        width: IMAGE_TYPE_BRAND_154w_66h_WIDTH,
        height: IMAGE_TYPE_BRAND_154w_66h_HEIGHT,
    },
    [IMAGE_TYPE_BRAND_312w_132h]: {
        name: IMAGE_TYPE_BRAND_312w_132h,
        width: IMAGE_TYPE_BRAND_312w_132h_WIDTH,
        height: IMAGE_TYPE_BRAND_312w_132h_HEIGHT,
    },
};

export const SVG_IMAGE_TYPES = {
    [IMAGE_TYPE_SVG]: {
        name: IMAGE_TYPE_SVG,
        width: IMAGE_TYPE_SVG_WIDTH,
        height: IMAGE_TYPE_SVG_HEIGHT,
    },
};

export const MARKETING_BANNER_IMAGE_TYPES = {
    [IMAGE_TYPE_MARKETING_BANNER_1532w_450h]: {
        name: IMAGE_TYPE_MARKETING_BANNER_1532w_450h,
        width: IMAGE_TYPE_MARKETING_BANNER_1532w_450h_WIDTH,
        height: IMAGE_TYPE_MARKETING_BANNER_1532w_450h_HEIGHT,
    },
    [IMAGE_TYPE_MARKETING_BANNER_1152w_450h]: {
        name: IMAGE_TYPE_MARKETING_BANNER_1152w_450h,
        width: IMAGE_TYPE_MARKETING_BANNER_1152w_450h_WIDTH,
        height: IMAGE_TYPE_MARKETING_BANNER_1152w_450h_HEIGHT,
    },
    [IMAGE_TYPE_MARKETING_BANNER_767w_450h]: {
        name: IMAGE_TYPE_MARKETING_BANNER_767w_450h,
        width: IMAGE_TYPE_MARKETING_BANNER_767w_450h_WIDTH,
        height: IMAGE_TYPE_MARKETING_BANNER_767w_450h_HEIGHT,
    },
    [IMAGE_TYPE_MARKETING_BANNER_360w_450h]: {
        name: IMAGE_TYPE_MARKETING_BANNER_360w_450h,
        width: IMAGE_TYPE_MARKETING_BANNER_360w_450h_WIDTH,
        height: IMAGE_TYPE_MARKETING_BANNER_360w_450h_HEIGHT,
    },
    [IMAGE_TYPE_MARKETING_BANNER_450w_450h]: {
        name: IMAGE_TYPE_MARKETING_BANNER_450w_450h,
        width: IMAGE_TYPE_MARKETING_BANNER_450w_450h_WIDTH,
        height: IMAGE_TYPE_MARKETING_BANNER_450w_450h_HEIGHT,
    },
};

export const MARKETING_DISPLAY_IMAGE_TYPES = {
    [IMAGE_TYPE_DISPLAY_768w_457h]: {
        name: IMAGE_TYPE_MAGENTO,
        width: IMAGE_TYPE_DISPLAY_768w_457h_WIDTH,
        height: IMAGE_TYPE_DISPLAY_768w_457h_HEIGHT,
    },
    [IMAGE_TYPE_DISPLAY_1325w_250h]: {
        name: IMAGE_TYPE_MAGENTO,
        width: IMAGE_TYPE_DISPLAY_1325w_250h_WIDTH,
        height: IMAGE_TYPE_DISPLAY_1325w_250h_HEIGHT,
    },
};

export const MARKETING_BANNER_HONE_IMAGE_TYPES = {
    [IMAGE_TYPE_MARKETING_BANNER_HONE_800w_800h]: {
        name: IMAGE_TYPE_MARKETING_BANNER_HONE_800w_800h,
        width: IMAGE_TYPE_MARKETING_BANNER_HONE_800w_800h_WIDTH,
        height: IMAGE_TYPE_MARKETING_BANNER_HONE_800w_800h_HEIGHT,
    },
    [IMAGE_TYPE_MARKETING_BANNER_HONE_600w_400h]: {
        name: IMAGE_TYPE_MARKETING_BANNER_HONE_600w_400h,
        width: IMAGE_TYPE_MARKETING_BANNER_HONE_600w_400h_WIDTH,
        height: IMAGE_TYPE_MARKETING_BANNER_HONE_600w_400h_HEIGHT,
    },
};

export const MARKETING_CARD_IMAGE_TYPES = {
    [IMAGE_TYPE_MARKETING_CARD_256w_256h]: {
        name: IMAGE_TYPE_MARKETING_CARD_256w_256h,
        width: IMAGE_TYPE_MARKETING_CARD_256w_256h_WIDTH,
        height: IMAGE_TYPE_MARKETING_CARD_256w_256h_HEIGHT,
    },
    [IMAGE_TYPE_MARKETING_CARD_512w_512h]: {
        name: IMAGE_TYPE_MARKETING_CARD_512w_512h,
        width: IMAGE_TYPE_MARKETING_CARD_512w_512h_WIDTH,
        height: IMAGE_TYPE_MARKETING_CARD_512w_512h_HEIGHT,
    },
};

export const MARKETING_CELEBRITY_IMAGE_TYPES = {
    [IMAGE_TYPE_MARKETING_CELEBRITY_492w_656h]: {
        name: IMAGE_TYPE_MARKETING_CELEBRITY_492w_656h,
        width: IMAGE_TYPE_MARKETING_CELEBRITY_492w_656h_WIDTH,
        height: IMAGE_TYPE_MARKETING_CELEBRITY_492w_656h_HEIGHT,
    },
    [IMAGE_TYPE_MARKETING_CELEBRITY_324w_432h]: {
        name: IMAGE_TYPE_MARKETING_CELEBRITY_324w_432h,
        width: IMAGE_TYPE_MARKETING_CELEBRITY_324w_432h_WIDTH,
        height: IMAGE_TYPE_MARKETING_CELEBRITY_324w_432h_HEIGHT,
    },
};

export const MARKETING_INFLUENCER_IMAGE_TYPES = {
    [IMAGE_TYPE_MARKETING_INFLUENCER_243w_324h]: {
        name: IMAGE_TYPE_MARKETING_INFLUENCER_243w_324h,
        width: IMAGE_TYPE_MARKETING_INFLUENCER_243w_324h_WIDTH,
        height: IMAGE_TYPE_MARKETING_INFLUENCER_243w_324h_HEIGHT,
    },
    [IMAGE_TYPE_MARKETING_INFLUENCER_318w_424h]: {
        name: IMAGE_TYPE_MARKETING_INFLUENCER_318w_424h,
        width: IMAGE_TYPE_MARKETING_INFLUENCER_318w_424h_WIDTH,
        height: IMAGE_TYPE_MARKETING_INFLUENCER_318w_424h_HEIGHT,
    },
    [IMAGE_TYPE_MARKETING_INFLUENCER_513w_684h]: {
        name: IMAGE_TYPE_MARKETING_INFLUENCER_513w_684h,
        width: IMAGE_TYPE_MARKETING_INFLUENCER_513w_684h_WIDTH,
        height: IMAGE_TYPE_MARKETING_INFLUENCER_513w_684h_HEIGHT,
    },
};

export const PROMO_CATEGORIES_SLIDER_IMAGE_TYPES = {
    [IMAGE_TYPE_PROMO_CATEGORY_COVER_256w_528h]: {
        name: IMAGE_TYPE_PROMO_CATEGORY_COVER_256w_528h,
        width: IMAGE_TYPE_PROMO_CATEGORY_COVER_256w_528h_WIDTH,
        height: IMAGE_TYPE_PROMO_CATEGORY_COVER_256w_528h_HEIGHT,
    },
    [IMAGE_TYPE_PROMO_CATEGORY_164w_164h]: {
        name: IMAGE_TYPE_PROMO_CATEGORY_164w_164h,
        width: IMAGE_TYPE_PROMO_CATEGORY_164w_164h_WIDTH,
        height: IMAGE_TYPE_PROMO_CATEGORY_164w_164h_HEIGHT,
    },
};

export const MARKETING_IMAGE_TYPES = {
    [IMAGE_TYPE_MARKETING_AVATAR_32w_32h]: {
        name: IMAGE_TYPE_MARKETING_AVATAR_32w_32h,
        width: IMAGE_TYPE_MARKETING_AVATAR_32w_32h_WIDTH,
        height: IMAGE_TYPE_MARKETING_AVATAR_32w_32h_HEIGHT,
    },
    [IMAGE_TYPE_MARKETING_BRAND_115w_55h]: {
        name: IMAGE_TYPE_MARKETING_BRAND_115w_55h,
        width: IMAGE_TYPE_MARKETING_BRAND_115w_55h_WIDTH,
        height: IMAGE_TYPE_MARKETING_BRAND_115w_55h_HEIGHT,
    },
    [IMAGE_TYPE_MARKETING_VALUE_PROPOSITION_198w_140h]: {
        name: IMAGE_TYPE_MARKETING_VALUE_PROPOSITION_198w_140h,
        width: IMAGE_TYPE_MARKETING_VALUE_PROPOSITION_198w_140h_WIDTH,
        height: IMAGE_TYPE_MARKETING_VALUE_PROPOSITION_198w_140h_HEIGHT,
    },
    [IMAGE_TYPE_MARKETING_BRANDS_SECTION_256w_106h]: {
        name: IMAGE_TYPE_MARKETING_BRANDS_SECTION_256w_106h,
        width: IMAGE_TYPE_MARKETING_BRANDS_SECTION_256w_106h_WIDTH,
        height: IMAGE_TYPE_MARKETING_BRANDS_SECTION_256w_106h_HEIGHT,
    },
};

export const MARKETING_BLOG_IMAGE_TYPES = {
    [IMAGE_TYPE_MARKETING_BLOG_328w_328h]: {
        name: IMAGE_TYPE_MARKETING_BLOG_328w_328h,
        width: IMAGE_TYPE_MARKETING_BLOG_328w_328h_WIDTH,
        height: IMAGE_TYPE_MARKETING_BLOG_328w_328h_HEIGHT,
    },
    [IMAGE_TYPE_MARKETING_BLOG_492w_492h]: {
        name: IMAGE_TYPE_MARKETING_BLOG_492w_492h,
        width: IMAGE_TYPE_MARKETING_BLOG_492w_492h_WIDTH,
        height: IMAGE_TYPE_MARKETING_BLOG_492w_492h_HEIGHT,
    },
    [IMAGE_TYPE_MARKETING_BLOG_492w_656h]: {
        name: IMAGE_TYPE_MARKETING_BLOG_492w_656h,
        width: IMAGE_TYPE_MARKETING_BLOG_492w_656h_WIDTH,
        height: IMAGE_TYPE_MARKETING_BLOG_492w_656h_HEIGHT,
    },
    [IMAGE_TYPE_MARKETING_BLOG_786w_786h]: {
        name: IMAGE_TYPE_MARKETING_BLOG_786w_786h,
        width: IMAGE_TYPE_MARKETING_BLOG_786w_786h_WIDTH,
        height: IMAGE_TYPE_MARKETING_BLOG_786w_786h_HEIGHT,
    },
    [IMAGE_TYPE_MARKETING_BLOG_786w_1048h]: {
        name: IMAGE_TYPE_MARKETING_BLOG_786w_1048h,
        width: IMAGE_TYPE_MARKETING_BLOG_786w_1048h_WIDTH,
        height: IMAGE_TYPE_MARKETING_BLOG_786w_1048h_HEIGHT,
    },
};

export const IMAGE_TYPES = {
    ...PRODUCT_IMAGE_TYPES,
    ...BRAND_IMAGE_TYPES,
    ...SVG_IMAGE_TYPES,
    ...MARKETING_BANNER_IMAGE_TYPES,
    ...MARKETING_DISPLAY_IMAGE_TYPES,
    ...MARKETING_BANNER_HONE_IMAGE_TYPES,
    ...MARKETING_BLOG_IMAGE_TYPES,
    ...MARKETING_CARD_IMAGE_TYPES,
    ...MARKETING_CELEBRITY_IMAGE_TYPES,
    ...MARKETING_INFLUENCER_IMAGE_TYPES,
    ...MARKETING_IMAGE_TYPES,
    ...MAGENTO_IMAGE_TYPES,
    ...PROMO_CATEGORIES_SLIDER_IMAGE_TYPES,
};

export const IMAGE_FORMAT_WEBP_EXTENSION = 'webp';
export const IMAGE_FORMAT_WEBP_MIME_TYPE = 'image/webp';
export const IMAGE_FORMAT_JPG_EXTENSION = 'jpg';
export const IMAGE_FORMAT_JPG_MIME_TYPE = 'image/jpeg';
export const IMAGE_FORMAT_SVG_EXTENSION = 'svg';
export const IMAGE_FORMAT_SVG_MIME_TYPE = 'image/svg+xml';

export const IMAGE_FORMAT_WEBP = {
    extension: IMAGE_FORMAT_WEBP_EXTENSION,
    mimeType: IMAGE_FORMAT_WEBP_MIME_TYPE,
};

export const IMAGE_FORMAT_JPG = {
    extension: IMAGE_FORMAT_JPG_EXTENSION,
    mimeType: IMAGE_FORMAT_JPG_MIME_TYPE,
};

export const IMAGE_FORMAT_SVG = {
    extension: IMAGE_FORMAT_SVG_EXTENSION,
    mimeType: IMAGE_FORMAT_SVG_MIME_TYPE,
};

export const DEFAULT_IMAGE_FORMAT = IMAGE_FORMAT_JPG;

export const ADDITIONAL_IMAGE_FORMATS = [IMAGE_FORMAT_WEBP];
export const SOURCE_IMAGE_FORMATS = [IMAGE_FORMAT_WEBP, IMAGE_FORMAT_JPG];

export const IMAGE_PLACEHOLDERS = {
    [IMAGE_TYPE_BRAND_154w_66h]: {
        url: '',
        width: IMAGE_TYPE_BRAND_154w_66h_WIDTH,
        height: IMAGE_TYPE_BRAND_154w_66h_HEIGHT,
        alt: 'eobuwie',
    },
    [IMAGE_TYPE_BRAND_312w_132h]: {
        url: '',
        width: IMAGE_TYPE_BRAND_312w_132h_WIDTH,
        height: IMAGE_TYPE_BRAND_312w_132h_HEIGHT,
        alt: 'eobuwie',
    },
    [IMAGE_TYPE_PRODUCT_64w_64h]: {
        url: '',
        width: IMAGE_TYPE_PRODUCT_64w_64h_WIDTH,
        height: IMAGE_TYPE_PRODUCT_64w_64h_HEIGHT,
        alt: 'eobuwie',
    },
    [IMAGE_TYPE_PRODUCT_128w_128h]: {
        url: '',
        width: IMAGE_TYPE_PRODUCT_128w_128h_WIDTH,
        height: IMAGE_TYPE_PRODUCT_128w_128h_HEIGHT,
        alt: 'eobuwie',
    },
    [IMAGE_TYPE_PRODUCT_256w_256h]: {
        url: '',
        width: IMAGE_TYPE_PRODUCT_256w_256h_WIDTH,
        height: IMAGE_TYPE_PRODUCT_256w_256h_HEIGHT,
        alt: 'eobuwie',
    },
    [IMAGE_TYPE_PRODUCT_512w_512h]: {
        url: '',
        width: IMAGE_TYPE_PRODUCT_512w_512h_WIDTH,
        height: IMAGE_TYPE_PRODUCT_512w_512h_HEIGHT,
        alt: 'eobuwie',
    },
    [IMAGE_TYPE_PRODUCT_132w_176h]: {
        url: '',
        width: IMAGE_TYPE_PRODUCT_132w_176h_WIDTH,
        height: IMAGE_TYPE_PRODUCT_132w_176h_HEIGHT,
        alt: 'eobuwie',
    },
    [IMAGE_TYPE_PRODUCT_528w_704h]: {
        url: '',
        width: IMAGE_TYPE_PRODUCT_528w_704h_WIDTH,
        height: IMAGE_TYPE_PRODUCT_528w_704h_HEIGHT,
        alt: 'eobuwie',
    },
    [IMAGE_TYPE_PRODUCT_660w_880h]: {
        url: '',
        width: IMAGE_TYPE_PRODUCT_660w_880h_WIDTH,
        height: IMAGE_TYPE_PRODUCT_660w_880h_HEIGHT,
        alt: 'eobuwie',
    },
    [IMAGE_TYPE_PRODUCT_1800w_2400h]: {
        url: '',
        width: IMAGE_TYPE_PRODUCT_1800w_2400h_WIDTH,
        height: IMAGE_TYPE_PRODUCT_1800w_2400h_HEIGHT,
        alt: 'eobuwie',
    },
    [IMAGE_TYPE_MARKETING_CARD_256w_256h]: {
        url: '',
        width: IMAGE_TYPE_MARKETING_CARD_256w_256h_WIDTH,
        height: IMAGE_TYPE_MARKETING_CARD_256w_256h_HEIGHT,
        alt: 'eobuwie',
    },
    [IMAGE_TYPE_MARKETING_CARD_512w_512h]: {
        url: '',
        width: IMAGE_TYPE_MARKETING_CARD_512w_512h_WIDTH,
        height: IMAGE_TYPE_MARKETING_CARD_512w_512h_HEIGHT,
        alt: 'eobuwie',
    },
    [IMAGE_TYPE_MARKETING_CELEBRITY_492w_656h]: {
        url: '',
        width: IMAGE_TYPE_MARKETING_CELEBRITY_492w_656h_WIDTH,
        height: IMAGE_TYPE_MARKETING_CELEBRITY_492w_656h_HEIGHT,
        alt: 'eobuwie',
    },
    [IMAGE_TYPE_MARKETING_BLOG_786w_1048h]: {
        url: '',
        width: IMAGE_TYPE_MARKETING_BLOG_786w_1048h_WIDTH,
        height: IMAGE_TYPE_MARKETING_BLOG_786w_1048h_HEIGHT,
        alt: 'eobuwie',
    },
    [IMAGE_TYPE_MARKETING_BLOG_786w_786h]: {
        url: '',
        width: IMAGE_TYPE_MARKETING_BLOG_786w_786h_WIDTH,
        height: IMAGE_TYPE_MARKETING_BLOG_786w_786h_HEIGHT,
        alt: 'eobuwie',
    },
    [IMAGE_TYPE_MARKETING_BANNER_HONE_800w_800h]: {
        url: '',
        width: IMAGE_TYPE_MARKETING_BANNER_HONE_800w_800h_WIDTH,
        height: IMAGE_TYPE_MARKETING_BANNER_HONE_800w_800h_HEIGHT,
        alt: 'eobuwie',
    },
    [IMAGE_TYPE_MARKETING_BANNER_HONE_600w_400h]: {
        url: '',
        width: IMAGE_TYPE_MARKETING_BANNER_HONE_600w_400h_WIDTH,
        height: IMAGE_TYPE_MARKETING_BANNER_HONE_600w_400h_HEIGHT,
        alt: 'eobuwie',
    },
};
