export const insertStringAfterSymbol = (input, insertStr, symbol = '|') => {
    if (!input) {
        return;
    }

    if (!insertStr) {
        return input;
    }

    const parts = input.split(symbol);

    return parts.length > 1
        ? `${parts[0].trim()} ${symbol} ${insertStr} ${parts[1].trim()}`
        : `${input} ${symbol} ${insertStr}`;
};
