import { DEFAULT_ZOWIE_CHAT_SRC } from '@assets/zowie-chat';

export const createDefaultGlobalConfig = () => ({
    isZowieChatEnabled: false,
    zowieChatEnabledPages: [],
    zowieScriptSrc: DEFAULT_ZOWIE_CHAT_SRC,
    zowieChatInstanceId: '',
    isInviteFriendEnabledFlag: false,
    isInviteFriendPausedFlag: false,
    isMagicLinkEnabled: false,
    isAccountVerificationEnabled: false,
    facebookUrl: '',
    instagramUrl: '',
    youtubeUrl: '',
    freeDeliveryText: '',
    synthroneScriptUrl: '',
    synthroneBrands: '',
});

export const createDefaultHelpCenterConfig = () => ({
    contactFormLink: '',
});

export default () => ({
    localeConfig: {},
    storeViews: [],
    storeView: {
        synerise: {
            api_key: '',
            campaigns: {},
        },
    },
    baseUrl: '',
    cookies: '',
    globalConfig: {
        ...createDefaultGlobalConfig(),
    },
    helpCenterConfig: {
        ...createDefaultHelpCenterConfig(),
    },
    googleAnalyticsConfig: {},
});
