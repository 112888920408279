<template>
    <form
        autocomplete="off"
        class="header-search-input"
        @submit.prevent="searchSubmit()"
    >
        <SearchComponent
            id="search-input-field-header-id"
            ref="searchInput"
            v-model="headerSearchValue"
            name="input-field-search-name"
            :placeholder="$t('Search')"
            @input="handleAutocompleteRequest()"
            @click.native="openAutocomplete()"
            @focus="focusHandler()"
            @blur="blurHandler()"
        />

        <ButtonIcon
            v-if="isMobile"
            :variant="BUTTON_VARIANT"
            class="back"
            @click.native="closeAutocomplete()"
        >
            <ChevronLeft width="24px" height="24px" />
        </ButtonIcon>
    </form>
</template>

<script>
import debounce from 'lodash.debounce';
import { createNamespacedHelpers, mapState } from 'vuex';

import { SEARCH_RESULTS_PAGE_NAME } from '@search/routing/names';

import { ChevronLeft } from '@eobuwie-ui/icons/v2/navigation';
import { SearchComponent } from '@eobuwie-ui/components/SearchComponent/v1';
import {
    ButtonIcon,
    BUTTON_ICON_VARIANTS,
} from '@eobuwie-ui/components/ButtonIcon/v1';

const AUTOCOMPLETE_DEBOUNCE_TIME = 500;

const { mapActions: mapNavigationActions } = createNamespacedHelpers(
    'navigation'
);
const {
    mapActions: mapAutocompleteActions,
    mapState: mapAutocompleteState,
} = createNamespacedHelpers('header/autocomplete');

export default {
    name: 'HeaderSearchInput',

    components: {
        SearchComponent,
        ButtonIcon,
        ChevronLeft,
    },

    props: {
        focusOnMount: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            headerSearchValue: '',
        };
    },

    computed: {
        ...mapState(['isMobile']),
        ...mapAutocompleteState(['autocompleteVisited']),

        isSearchQueryValid() {
            return this.headerSearchValue?.length > 1;
        },
    },

    watch: {
        $route() {
            const query = this.$route.query.q || '';

            this.headerSearchValue =
                this.$route.name === SEARCH_RESULTS_PAGE_NAME
                    ? decodeURI(query)
                    : '';

            if (!this.isSearchQueryValid) {
                this.clearResults();
                this.setQuery('');
            }
        },
    },

    beforeCreate() {
        this.BUTTON_VARIANT = BUTTON_ICON_VARIANTS.SECONDARY;
    },

    beforeMount() {
        this.handleAutocompleteRequest = debounce(function () {
            this.getAutocomplete();
        }, AUTOCOMPLETE_DEBOUNCE_TIME);
    },

    async mounted() {
        this.headerSearchValue = decodeURI(this.$route.query.q || '');
        this.setQuery(this.headerSearchValue);

        await this.$nextTick();

        if (this.focusOnMount) {
            this.$refs.searchInput?.focus?.();
        }
    },

    beforeDestroy() {
        this.handleAutocompleteRequest.cancel();
    },

    methods: {
        ...mapAutocompleteActions([
            'getAutocompleteData',
            'setQuery',
            'clearResults',
            'setAutocompleteVisited',
            'openAutocomplete',
            'getSearchUrl',
        ]),

        ...mapNavigationActions(['setIsSearchInputActive']),

        async getAutocomplete() {
            this.setQuery(this.headerSearchValue);

            if (!this.isSearchQueryValid) {
                this.clearResults();

                return;
            }

            await this.getAutocompleteData(this.headerSearchValue);
        },

        closeAutocomplete() {
            this.$modals.close('autocomplete');
        },

        async searchSubmit() {
            const query = this.headerSearchValue;
            const url = await this.getSearchUrl({
                query,
            });

            if (!url) {
                return;
            }

            this.$router.push({ path: url });
        },

        focusHandler() {
            this.setIsSearchInputActive(true);
        },

        blurHandler() {
            this.setIsSearchInputActive(false);
        },
    },
};
</script>

<style scoped lang="scss">
.header-search-input {
    @apply relative;

    :deep(.search-component .input) {
        @apply pl-ui-9;
    }

    .back {
        @apply absolute left-0 top-0 z-1;
    }
}
</style>
