export const DEFAULT_DEBOUNCE_TIME = 1000;

export const HEADING_SLOT_INDEX = 0;
export const SUBHEADING_SLOT_INDEX = 1;
export const LINK_SLOT_INDEX = 2;
export const MODIVO_PRODUCTS_SLOT_INDEX = 3;
export const MODIVO_SLOT_NAME = 'MODIVO';
export const DEFAULT_SLOT_CONTENT = 'Unnamed slot';
export const EMPTY_SLOT_CONTENT = '-';

export const AB_TEST_NAME_PREFIX = 'ab_test_reko_';

export const RECOMMENDATIONS_STICKERS_TEST_NAME = 'eob_recommendation_stickers';

export const AB_TEST_RECO_SIZE_FILTER = 'eob_all_pdp_reco_size_filter';
