export const ANDROID_APP_LINK_NAME = 'ANDROID_APP_LINK';
export const CHAT_LINKS_NAME = 'CHAT_LINKS';
export const COUNTRY_CENTER_MAP_NAME = 'COUNTRY_CENTER_MAP';
export const CURRENCY_UNIT_NAME = 'CURRENCY_UNIT';
export const DEFAULT_SEARCH_CATEGORY_NAME = 'DEFAULT_SEARCH_CATEGORY';
export const IS_HREFLANG_ALLOWED = 'IS_HREFLANG_ALLOWED';
export const IS_LIVE_CHAT_ALLOWED = 'IS_LIVE_CHAT_ALLOWED';
export const IOS_APP_LINK_NAME = 'IOS_APP_LINK';
export const LOGO_EOB_IMPORT_NAME = 'LOGO_EOB_IMPORT';
export const MODIVO_DOMAIN_NAME = 'MODIVO_DOMAIN';
export const NEWSLETTER_ADDITIONAL_TEXT_ALLOWED_NAME =
    'NEWSLETTER_ADDITIONAL_TEXT_ALLOWED';
export const NIP_VALIDATION_RULE_NAME = 'NIP_VALIDATION_RULE';
export const OG_IMAGE_IMPORT_NAME = 'OG_IMAGE_IMPORT';
export const PAYU_CUSTOM_ICON_NAME = 'PAYU_CUSTOM_ICON';
export const PHONE_PREFIX_NAME = 'PHONE_PREFIX';
export const SHIPPING_METHODS_DELIVERY_TIME_NAME =
    'SHIPPING_METHODS_DELIVERY_TIME';
export const SHIPPING_METHODS_DELIVERY_TIME_DEFAULT_NAME =
    'SHIPPING_METHODS_DELIVERY_TIME_DEFAULT_NAME';
export const SPEEDY_POINTS_SHIPPING_METHOD_CARRIER_CODE = 'speedyparcelshop';
export const POSTCODE_VALIDATION_RULE_NAME = 'POSTCODE_VALIDATION_RULE';
export const SHOW_DUTY_INFORMATION_NAME = 'SHOW_DUTY_INFORMATION';
export const SHOW_SHOP_LOCATIONS_NAME = 'SHOW_SHOP_LOCATIONS';
export const SHOW_SOCIAL_PROOF_WIDGET_NAME = 'SHOW_SOCIAL_PROOF_WIDGET';
export const STORE_LANGUAGE_SWITCHER_MAP_NAME = 'STORE_LANGUAGE_SWITCHER_MAP';
export const SYNERISE_CONFIG_NAME = 'SYNERISE_CONFIG';
export const IS_MINIMAL_PRICE_ENABLED = 'IS_MINIMAL_PRICE_ENABLED';
export const SHOW_SAFE_AREA_DELIVERY = 'SHOW_SAFE_AREA_DELIVERY';
export const FAST_DELIVERY_WAREHOUSE_CODE_NAME =
    'FAST_DELIVERY_WAREHOUSE_CODE_NAME';
export const MODAL_PAYPO_IFRAME_SRC = 'MODAL_PAYPO_IFRAME_SRC';
export const LEGAL_INFORMATION_FIRST = 'LEGAL_INFORMATION_FIRST';
export const LEGAL_INFORMATION_SECOND = 'LEGAL_INFORMATION_SECOND';
export const SHOW_SPACE_BEFORE_PUNCTUATION = 'SHOW_SPACE_BEFORE_PUNCTUATION';
export const IS_SPONSORED_SLIDER_ENABLED = 'IS_SPONSORED_SLIDER_ENABLED';
export const IS_COD_ACCORDION_EXPANDED = 'IS_COD_ACCORDION_EXPANDED';
export const DAYS_FOR_RETURNS = 'DAYS_FOR_RETURNS';
export const MOBILE_APP_LINK_PDP = 'MOBILE_APP_LINK_PDP';
export const IS_NAME_HINT_VISIBLE = 'IS_NAME_HINT_VISIBLE';
export const IS_CURRENCY_BEFORE_ENABLED = 'IS_CURRENCY_BEFORE_ENABLED';

export const SHIPPING_METHODS_DELIVERY_TIME = {
    MIN_NAME: 'SHIPPING_METHODS_DELIVERY_TIME_MIN',
    MAX_NAME: 'SHIPPING_METHODS_DELIVERY_TIME_MAX',
};

export const CHAT_LINKS = {
    MESSENGER_NAME: 'MESSENGER',
    WHATSAPP_NAME: 'WHATSAPP',
};

export const SHOW_REVERSED_STREET_INPUTS = 'SHOW_REVERSED_STREET_INPUTS';
export const KLARNA_WIDGET_CLIENT_ID = 'KLARNA_WIDGET_CLIENT_ID';
export const IS_OMNIBUS_STRICT_ENABLED = 'IS_OMNIBUS_STRICT_ENABLED';
export const IS_CUT_OFF_THE_CENTS_ENABLED = 'IS_CUT_OFF_THE_CENTS_ENABLED';
export const IS_DISCOUNT_ENABLED = 'IS_DISCOUNT_ENABLED';
export const IS_REGULAR_PRICE_ENABLED = 'IS_REGULAR_PRICE_ENABLED';

export const SHOULD_CHANGE_CATALOG_ROBOTS = 'SHOULD_CHANGE_CATALOG_ROBOTS';

export const STATIONARY_SHOP = 'STATIONARY_SHOP';
export const MOBILE_APP_LINK_HEADER = 'MOBILE_APP_LINK_HEADER';
export const BLOG_LINK = 'BLOG_LINK';

export const LOCALE_CONFIG_KEYS_MAP = [
    ANDROID_APP_LINK_NAME,
    CHAT_LINKS_NAME,
    COUNTRY_CENTER_MAP_NAME,
    CURRENCY_UNIT_NAME,
    DEFAULT_SEARCH_CATEGORY_NAME,
    IS_LIVE_CHAT_ALLOWED,
    IOS_APP_LINK_NAME,
    LOGO_EOB_IMPORT_NAME,
    MODIVO_DOMAIN_NAME,
    NEWSLETTER_ADDITIONAL_TEXT_ALLOWED_NAME,
    NIP_VALIDATION_RULE_NAME,
    OG_IMAGE_IMPORT_NAME,
    PAYU_CUSTOM_ICON_NAME,
    PHONE_PREFIX_NAME,
    SHIPPING_METHODS_DELIVERY_TIME_NAME,
    POSTCODE_VALIDATION_RULE_NAME,
    SHOW_DUTY_INFORMATION_NAME,
    SHOW_SHOP_LOCATIONS_NAME,
    SHOW_SOCIAL_PROOF_WIDGET_NAME,
    STORE_LANGUAGE_SWITCHER_MAP_NAME,
    SYNERISE_CONFIG_NAME,
    IS_MINIMAL_PRICE_ENABLED,
    SHOW_SAFE_AREA_DELIVERY,
    FAST_DELIVERY_WAREHOUSE_CODE_NAME,
    MODAL_PAYPO_IFRAME_SRC,
    LEGAL_INFORMATION_FIRST,
    LEGAL_INFORMATION_SECOND,
    SHOW_REVERSED_STREET_INPUTS,
    SHOW_SPACE_BEFORE_PUNCTUATION,
    IS_SPONSORED_SLIDER_ENABLED,
    KLARNA_WIDGET_CLIENT_ID,
    IS_OMNIBUS_STRICT_ENABLED,
    IS_CUT_OFF_THE_CENTS_ENABLED,
    IS_DISCOUNT_ENABLED,
    IS_REGULAR_PRICE_ENABLED,
    SHOULD_CHANGE_CATALOG_ROBOTS,
    STATIONARY_SHOP,
    IS_COD_ACCORDION_EXPANDED,
    DAYS_FOR_RETURNS,
    MOBILE_APP_LINK_HEADER,
    MOBILE_APP_LINK_PDP,
    BLOG_LINK,
    IS_NAME_HINT_VISIBLE,
    IS_CURRENCY_BEFORE_ENABLED,
];

export const COMMON_LOCALE_CONFIG_KEYS_MAP = [IS_HREFLANG_ALLOWED];
