import { AB_TEST_NAME_PREFIX } from '@configs/recommendations';
import { MEGATRON_CLIENT_ENDPOINT_PARAMETER_SYNERISE } from '@configs/megatron-client';

import {
    SYNERISE_RECOMMENDATION_TYPES_WITH_NO_UUID,
    SYNERISE_RECOMMENDATION_TYPES_WITH_PRODUCT_SKU,
} from '@types/Synerise';

import { SyneriseCampaignQuery } from '@eobuwie/synerise-recommendations';

import { FILTERS_JOINERS } from '@eobuwie/synerise-recommendations/dist/types/filters';

import { snakeCase } from '@assets/string-utility';

export default (megatronClient, { abTests } = {}, config = {}) => ({
    async getById(
        type,
        category,
        productBrand,
        size = '',
        productSku = '',
        clientUUID = '',
        customCampaignId = '',
        locale = ''
    ) {
        const response = (recommendations, extras) => ({
            recommendations: recommendations || [],
            extras: extras || {},
        });
        const apiKey = config?.api_key;

        if (!apiKey) {
            return response();
        }

        const campaign =
            customCampaignId || this.getCampaignIdByType(type, locale);

        if (!campaign) {
            return response();
        }

        const syneriseQuery = new SyneriseCampaignQuery();

        syneriseQuery.setCampaign(campaign);

        if (category) {
            syneriseQuery
                .setAdditionalFilter('category', category)
                .setFiltersJoiner(FILTERS_JOINERS.FILTERS_JOINER_AND);
        }

        if (productBrand) {
            syneriseQuery
                .setAdditionalFilter('brand', productBrand)
                .setFiltersJoiner(FILTERS_JOINERS.FILTERS_JOINER_AND);
        }

        if (size !== null && size !== '') {
            syneriseQuery
                .setAdditionalFilter('size', size)
                .setFiltersJoiner(FILTERS_JOINERS.FILTERS_JOINER_AND);
        }

        if (!SYNERISE_RECOMMENDATION_TYPES_WITH_NO_UUID.includes(type)) {
            if (clientUUID === '') {
                return response();
            }

            syneriseQuery.setClientUUID(clientUUID);
        }

        if (SYNERISE_RECOMMENDATION_TYPES_WITH_PRODUCT_SKU.includes(type)) {
            if (productSku.length === 0) {
                return response();
            }

            syneriseQuery.setProductSku(productSku);
        }

        const { data } = await megatronClient.get(syneriseQuery.build(), {
            ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_SYNERISE,
            headers: {
                'x-api-key': apiKey,
            },
        });

        return response(data?.data, data?.extras);
    },

    getTestCampaignId(type, locale) {
        const countryCode = locale.slice(3, 5).toLowerCase();

        return abTests?.getVariant(
            `${AB_TEST_NAME_PREFIX}${countryCode}_${snakeCase(type)}`
        );
    },

    getCampaignIdByType(type, locale) {
        const { campaigns, placements } = config;

        if (!campaigns && !placements) {
            return '';
        }

        const testCampaign = this.getTestCampaignId(type, locale);

        return testCampaign || campaigns[type] || placements[type];
    },
});
