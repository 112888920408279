export const TYPE_CATEGORY = 'category';
export const TYPE_PRODUCT = 'product';
export const TYPE_PRODUCT_UNAVAILABLE = 'product_unavailable';
export const TYPE_BRAND = 'brand';
export const TYPE_CUSTOM = 'custom';
export const TYPE_CART_LAST_ITEM = 'last_cart_last_item';
export const TYPE_CART_USER = 'cart_user';
export const TYPE_CART = 'cart';
export const TYPE_EXIT_MODAL = 'exit_modal';
export const TYPE_REVIEWS = 'reviews';
export const TYPE_CUSTOMER_ORDERS_LIST = 'customer_orders_list';

export const WEB_EOB_PDP_1 = 'web-eob-pdp-1';
export const WEB_EOB_PDP_2 = 'web-eob-pdp-2';
export const WEB_EOB_PDP_SPONSORED = 'web-eob-pdp-sponsored';
export const WEB_EOB_PDP_UNAVBL_1 = 'web-eob-pdp-unavbl-1';
export const WEB_EOB_PRECART_1 = 'web-eob-precart-1';
export const WEB_EOB_CART_1 = 'web-eob-cart-1';
export const WEB_EOB_CATEGORY_1 = 'web-eob-category-1';
export const WEB_EOB_EMPTY_CART_1 = 'web-eob-empty-cart-1';
export const WEB_EOB_SEARCH_RESULTS_1 = 'web-eob-search-results-1';
export const WEB_EOB_SEARCH_NO_RESULTS_1 = 'web-eob-search-no-results-1';
export const WEB_EOB_ERROR_PAGE_1 = 'web-eob-error-page-1';
export const WEB_EOB_PDP_PRODUCT_NOTIFICATION =
    'web-eob-pdp-product-notification-1';
export const WEB_EOB_CART_SPONSORED = 'web-eob-cart-sponsored';
export const WEB_EOB_HOMEPAGE_SPONSORED = 'web-eob-homepage-sponsored';

export const SYNERISE_RECOMMENDATION_TYPES = {
    TYPE_CATEGORY,
    TYPE_PRODUCT,
    TYPE_PRODUCT_UNAVAILABLE,
    TYPE_BRAND,
    TYPE_CUSTOM,
    TYPE_CART_LAST_ITEM,
    TYPE_CART_USER,
    TYPE_CART,
    TYPE_EXIT_MODAL,
    TYPE_REVIEWS,
    TYPE_CUSTOMER_ORDERS_LIST,
};

export const SYNERISE_RECOMMENDATION_PLACEMENTS = {
    WEB_EOB_PDP_1,
    WEB_EOB_PDP_2,
    WEB_EOB_PDP_UNAVBL_1,
    WEB_EOB_CART_1,
    WEB_EOB_PRECART_1,
    WEB_EOB_CATEGORY_1,
    WEB_EOB_EMPTY_CART_1,
    WEB_EOB_SEARCH_NO_RESULTS_1,
    WEB_EOB_SEARCH_RESULTS_1,
    WEB_EOB_ERROR_PAGE_1,
    WEB_EOB_PDP_PRODUCT_NOTIFICATION,
};

export const SYNERISE_RECOMMENDATION_PRODUCT_PLACEMENTS = [
    WEB_EOB_PDP_UNAVBL_1,
    WEB_EOB_PRECART_1,
];

export const SYNERISE_RECOMMENDATION_SEARCH_RESULTS_PLACEMENTS = [
    WEB_EOB_SEARCH_RESULTS_1,
];

export const SYNERISE_RECOMMENDATION_SEARCH_NO_RESULTS_PLACEMENTS = [
    WEB_EOB_SEARCH_NO_RESULTS_1,
];

export const SYNERISE_RECOMMENDATION_CATEGORY_PLACEMENTS = [WEB_EOB_CATEGORY_1];

export const SYNERISE_RECOMMENDATION_CART_PLACEMENTS = [WEB_EOB_CART_1];

export const SYNERISE_RECOMMENDATION_TYPES_WITH_NO_UUID = [TYPE_CART];

export const SYNERISE_RECOMMENDATION_TYPES_WITH_PRODUCT_SKU = [
    TYPE_BRAND,
    TYPE_PRODUCT,
    TYPE_CART_LAST_ITEM,
    WEB_EOB_PDP_1,
    WEB_EOB_PDP_2,
    WEB_EOB_PDP_UNAVBL_1,
    WEB_EOB_CART_1,
    WEB_EOB_PRECART_1,
    WEB_EOB_PDP_PRODUCT_NOTIFICATION,
];
