<template>
    <HeaderTooltip :is-enabled="true">
        <HeaderActionButton
            :path="accountIconPath"
            :label="accountButtonText"
            :icon="Account"
            :analytics-click-event="HEADER_MY_ACCOUNT_CLICK"
        />
        <template #tooltipContent>
            <AccountMenu v-if="isLoggedIn" />
            <HeaderTooltipContent
                v-else
                :illustration="GreenUser"
                :heading="$t('Welcome to Eobuwie!')"
                :description="
                    $t(
                        'Log in and see your purchases, favorite products and notifications.'
                    )
                "
                :primary-button-text="$t('Login')"
                :secondary-button-text="$t('Create an account')"
                @primary-button-click="showLoginModal()"
                @secondary-button-click="showRegisterModal()"
            />
        </template>
    </HeaderTooltip>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import {
    MODAL_AUTHORIZATION_NAME,
    MODAL_AUTHORIZATION_MODAL_TYPE,
} from '@configs/modals';
import { REGISTER, LOGIN } from '@types/CustomerModal';

import {
    HEADER_MY_ACCOUNT_CLICK,
    HEADER_MY_ACCOUNT_MODAL_LOGIN_CLICK,
    HEADER_MY_ACCOUNT_MODAL_REGISTER_CLICK,
} from '@analytics-types/Events';

import { CUSTOMER_PAGE, LOGIN_PAGE } from '@router/paths';

import HeaderTooltip from '@header-atoms/HeaderTooltip/HeaderTooltip';
import HeaderActionButton from '@header-atoms/HeaderActionButton/HeaderActionButton';

import { Account } from '@eobuwie-ui/icons/v2/other';
import GreenUser from '@header/illustrations/green-user.svg?inline';

const { mapGetters: mapCustomerGetters } = createNamespacedHelpers('customer');

export default {
    name: 'AccountTooltip',

    components: {
        HeaderTooltip,
        HeaderActionButton,
        AccountMenu: () => ({
            component: import(
                // eslint-disable-next-line max-len
                /* webpackChunkName: "account-menu" */ '@header-molecules/AccountMenu/AccountMenu'
            ),
        }),

        HeaderTooltipContent: () => ({
            component: import(
                /* webpackChunkName: "header-tooltip-content" */
                '@header-atoms/HeaderTooltipContent/HeaderTooltipContent'
            ),
        }),
    },

    computed: {
        ...mapCustomerGetters(['isLoggedIn']),

        accountIconPath() {
            return this.isLoggedIn ? this.accountPath : this.loginPath;
        },

        accountButtonText() {
            return this.isLoggedIn ? this.$t('My account') : this.$t('Login');
        },
    },

    beforeCreate() {
        this.HEADER_MY_ACCOUNT_CLICK = HEADER_MY_ACCOUNT_CLICK;

        this.Account = Account;

        this.accountPath = `/${CUSTOMER_PAGE}`;
        this.loginPath = `/${LOGIN_PAGE}`;
        this.GreenUser = GreenUser;
    },

    methods: {
        async showRegisterModal() {
            await this.$modals.open(MODAL_AUTHORIZATION_NAME, {
                [MODAL_AUTHORIZATION_MODAL_TYPE]: REGISTER,
            });

            this.$analytics.emit(HEADER_MY_ACCOUNT_MODAL_REGISTER_CLICK);
        },

        async showLoginModal() {
            await this.$modals.open(MODAL_AUTHORIZATION_NAME, {
                [MODAL_AUTHORIZATION_MODAL_TYPE]: LOGIN,
            });

            this.$analytics.emit(HEADER_MY_ACCOUNT_MODAL_LOGIN_CLICK);
        },
    },
};
</script>
