import {
    ATTRIBUTE_TYPE_ASSETS_COLLECTION,
    ATTRIBUTE_TYPE_BOOLEAN,
    ATTRIBUTE_TYPE_MULTI_SELECT,
    ATTRIBUTE_TYPE_PRICE_COLLECTION,
    ATTRIBUTE_TYPE_SIMPLE_SELECT,
    ATTRIBUTE_TYPE_TEXT,
    ATTRIBUTE_TYPE_REFERENCE_ENTITY,
    ATTRIBUTE_TYPE_REFERENCE_ENTITY_COLLECTION,
    ATTRIBUTE_TYPE_NUMBER,
} from '@configs/product-attribute-types';
import {
    ACTIVE_ATTRIBUTE_NAME,
    BADGES_ATTRIBUTE_NAME,
    BRAND_ATTRIBUTE_NAME,
    BRAND_WITH_COLLECTION_ATTRIBUTE_NAME,
    COLOR_ATTRIBUTE_NAME,
    COLOR_ARRAY_ATTRIBUTE_NAME,
    FASON_ATTRIBUTE_NAME,
    FINAL_PRICE_ATTRIBUTE_NAME,
    IMAGES_ATTRIBUTE_NAME,
    MODEL_ATTRIBUTE_NAME,
    NAME_ATTRIBUTE_NAME,
    NAME_DISPLAY_ATTRIBUTE_NAME,
    PRICE_ATTRIBUTE_NAME,
    PRODUCER_ATTRIBUTE_NAME,
    PRODUCT_GROUP_ASSOCIATED_ATTRIBUTE_NAME,
    PURPOSE_ATTRIBUTE_NAME,
    SERIES_NAME_ATTRIBUTE_NAME,
    SEX_NAME_ATTRIBUTE_NAME,
    SIZE_TYPE_NAME_ATTRIBUTE_NAME,
    SKU_ATTRIBUTE_NAME,
    STICKER_ATTRIBUTE_NAME,
    STYLE_ATTRIBUTE_NAME,
    URL_KEY_ATTRIBUTE_NAME,
    VIDEO_ATTRIBUTE_NAME,
    OCCASION_ATTRIBUTE_NAME,
    PREMIUM_ATTRIBUTE_NAME,
    ECO_FRIENDLY_ATTRIBUTE_NAME,
    HOT_DEAL_ATTRIBUTE_NAME,
    SIZE_SUGGESTION_ATTRIBUTE_NAME,
    NEW_ATTRIBUTE_NAME,
    PRODUCT_COLOR_VARIANTS_COUNT_ATTRIBUTE_NAME,
    OMNIBUS_PRICE_ATTRIBUTE_NAME,
    OMNIBUS_DISCOUNT_ATTRIBUTE_NAME,
    DISCOUNT_ATTRIBUTE_NAME,
    PRODUCT_CARD_SIZE_ATTRIBUTE_NAME,
    SHORT_NAME_ATTRIBUTE_NAME,
} from '@configs/product-attribute-names';
import { IS_ECO_FRIENDLY_VALUE_CODE } from '@configs/eco-friendly';
import {
    MAX_BADGES_TO_DISPLAY,
    PRODUCT_ONE_SIZE_LABEL,
} from '@configs/product';
import { TRUE_SIZE_TYPES } from '@configs/esizeme';
import { COLORS } from '@configs/filter-main-colors';

import {
    DEFAULT_SEARCH_CATEGORY_NAME,
    IS_DISCOUNT_ENABLED,
    IS_OMNIBUS_STRICT_ENABLED,
    IS_CUT_OFF_THE_CENTS_ENABLED,
    IS_REGULAR_PRICE_ENABLED,
} from '@localeConfig/keys';

import { DEFAULT_LOCALE } from '@analytics-types/Analytics';
import {
    IMAGE_TYPE_PRODUCT_64w_64h,
    IMAGE_TYPE_PRODUCT_128w_128h,
    IMAGE_TYPE_PRODUCT_132w_176h,
    IMAGE_TYPE_PRODUCT_256w_256h,
    IMAGE_TYPE_PRODUCT_512w_512h,
    IMAGE_TYPE_PRODUCT_528w_704h,
    IMAGE_TYPE_PRODUCT_660w_880h,
    IMAGE_TYPE_PRODUCT_1800w_2400h,
    IMAGE_PLACEHOLDERS,
    IMAGE_TYPE_BRAND_154w_66h,
    DEFAULT_IMAGE_FORMAT,
    SOURCE_IMAGE_FORMATS,
} from '@types/Image';
import {
    TYPE_COLOR_MAP,
    COLOR_GREEN_LIGHT,
    TYPE_NEW,
    TYPE_DISCOUNT,
    TYPE_ECO_FRIENDLY,
    BADGE_VARIANTS_MAP,
} from '@types/Badge';

import { PRODUCT_IMAGE_SEX_TYPES } from '@types/ProductImage';

import { buildProductPriceObject } from '@assets/price';
import {
    getDiscountAmountAndFormatted,
    getDiscountBadge,
} from '@assets/discount';
import { getVimeoVideoUrl, getYoutubeVideoUrl } from '@assets/video';
import { stringifyCategoryUrl } from '@assets/path';
import {
    getProductVariantLabel,
    getChosenSizeLabelFromVariant,
} from '@assets/product';
import { sortProductVariantsBySortOrder } from '@assets/product-variants';
import { getProductImage } from '@assets/images';
import {
    getValidPromoSticker,
    getHotDealPromoSticker,
} from '@assets/product-promo-stickers';

export default class Product {
    constructor(
        {
            id,
            values,
            variants,
            categories,
            family,
            associations,
            attributes,
            sponsorship_details: sponsorshipDetails = {},
        },
        locale,
        currency,
        $createProductPath,
        $createCategoryPath,
        $imaginator,
        $getLocaleConfigByKey,
        $t,
        timezone = '',
        withDesignSystem = false
    ) {
        const valuesWithAttributes = Object.fromEntries(
            Object.entries(values).map(([key, value]) => {
                return [
                    key,
                    {
                        ...value,
                        attribute: attributes?.[key] || value.attribute,
                    },
                ];
            })
        );

        this.id = id;
        this.values = valuesWithAttributes;
        this.attributes = attributes;
        this.variants = variants;
        this.categories = categories;
        this.family = family;
        this.locale = locale;
        this.currency = currency;
        this.associations = associations;
        this.sponsorshipDetails = sponsorshipDetails;
        this.$createProductPath = $createProductPath;
        this.$createCategoryPath = $createCategoryPath;
        this.$imaginator = $imaginator;
        this.storeViewTimezone = timezone;
        this.$getLocaleConfigByKey = $getLocaleConfigByKey;
        this.isOmnibusEnabled = $getLocaleConfigByKey(
            IS_OMNIBUS_STRICT_ENABLED
        );
        this.$t = $t;
        this.withDesignSystem = withDesignSystem;
    }

    buildDetails() {
        const { locale } = this;

        const product = {
            badges: this.getBadges(locale),
            brandName: this.getBrandName(),
            brandImage: this.getBrandImage(),
            brandUrl: this.getBrandUrl(),
            color: this.getColor(),
            colorVariantsLink: this.getColorVariantsLink(),
            colorVariantsCount: this.getColorVariantsCount(),
            fason: this.getFason(),
            images: this.getImages([
                IMAGE_TYPE_PRODUCT_64w_64h,
                IMAGE_TYPE_PRODUCT_128w_128h,
                IMAGE_TYPE_PRODUCT_132w_176h,
                IMAGE_TYPE_PRODUCT_256w_256h,
                IMAGE_TYPE_PRODUCT_512w_512h,
                IMAGE_TYPE_PRODUCT_528w_704h,
                IMAGE_TYPE_PRODUCT_660w_880h,
                IMAGE_TYPE_PRODUCT_1800w_2400h,
            ]),
            isActive: this.getIsActive(),
            category: Product.getCategory(this.locale, this.categories),
            mainCategoryUrl: Product.getMainCategoryUrl(
                this.locale,
                this.$getLocaleConfigByKey,
                this.categories
            ),
            currency: this.currency,
            model: this.getModel(),
            name: this.getName(),
            shortName: this.getShortName(),
            shortNameFromAttribute: this.getShortNameFromAttribute(),
            nameDisplay: this.getNameDisplay(),
            price: this.getPrice(),
            promoSticker: this.getPromoSticker(),
            seriesName: this.getSeriesName(),
            sizeType: this.getSizeType(),
            sku: this.getSku(),
            url: this.getUrl(),
            urlKey: this.getUrlKey(),
            video: this.getVideo(),
            id: this.id,
            family: this.family,
            variants: this.variants,
            values: this.values,
            attributes: this.attributes,
            categories: this.categories,
            associations: this.associations,
            analytics: this.buildAnalytics(),
            isInStock: this.getIsInStock(),
            isPremium: this.getIsPremium(),
            isOneSize: this.getIsOneSize(),
            isEcoFriendly: this.getIsEcoFriendly(),
            chosenSize: this.getChosenSize(),
            sizeSuggestion: this.getSizeSuggestion(),
            isSponsored: this.getIsSponsored(),
            adTechEvents: this.getAdTechEvents(),
            hasTrueSize: this.getHasTrueSize(),
            hasOneVariant: this.getHasOneVariant(),
        };

        if (this.withDesignSystem) {
            product.designSystem = this.buildDesignSystem(product);
        }

        return product;
    }

    buildAnalytics() {
        return {
            sku: this.getSku(DEFAULT_LOCALE),
            name: this.getName(),
            shortName: this.getShortName(DEFAULT_LOCALE),
            nameDisplay: this.getNameDisplay(DEFAULT_LOCALE),
            price: this.getPrice(),
            brandName: this.getBrandName(DEFAULT_LOCALE),
            categories: this.categories,
            color: this.getColor(DEFAULT_LOCALE),
            fason: this.getFason(DEFAULT_LOCALE),
            variants: this.variants,
            isActive: this.getIsActive(DEFAULT_LOCALE),
            model: this.getModel(DEFAULT_LOCALE),
            seriesName: this.getSeriesName(DEFAULT_LOCALE),
            badges: this.getBadges(DEFAULT_LOCALE, true, true),
            occasion: this.getOccasion(DEFAULT_LOCALE),
            urlKey: this.getUrlKey(DEFAULT_LOCALE),
            localeDefault: DEFAULT_LOCALE,
            sexName: this.getSexName(DEFAULT_LOCALE),
            purpose: this.getPurpose(DEFAULT_LOCALE),
            style: this.getStyle(DEFAULT_LOCALE),
            isPremium: this.getIsPremium(DEFAULT_LOCALE),
            isSponsored: this.getIsSponsored(),
            hasTrueSize: this.getHasTrueSize(),
            mainImageType: this.getMainImageType(),
        };
    }

    buildDesignSystem(product) {
        const isCentsEnabled = !this.$getLocaleConfigByKey(
            IS_CUT_OFF_THE_CENTS_ENABLED
        );

        const isRegularPriceEnabled = this.$getLocaleConfigByKey(
            IS_REGULAR_PRICE_ENABLED
        );

        const promoBadge = product.promoSticker && {
            color: product.promoSticker.textColor,
            backgroundColor: product.promoSticker.bgColor,
            label: product.promoSticker.formattedContent,
        };

        const { discount, promotional, regular, omnibus } = product.price;

        const isDiscounted = this.isOmnibusEnabled
            ? discount && omnibus?.amount > promotional.amount
            : discount && promotional.amount < regular.amount;

        const arePricesTheSame =
            promotional.amount === regular.amount &&
            promotional.amount === omnibus?.amount;

        const priceMinimal = arePricesTheSame
            ? null
            : product.price.omnibus?.formatted || '';

        const showOmnibus = this.isOmnibusEnabled
            ? !arePricesTheSame && priceMinimal && (isDiscounted || promoBadge)
            : false;

        const priceRegular = isRegularPriceEnabled
            ? product.price.regular.formatted
            : null;

        const showPrevious =
            isRegularPriceEnabled && !this.isOmnibusEnabled && isDiscounted;

        return {
            id: product.sku,
            images: product.images.map((_, index) => {
                const image = getProductImage(
                    product,
                    index,
                    [
                        IMAGE_TYPE_PRODUCT_256w_256h,
                        IMAGE_TYPE_PRODUCT_512w_512h,
                    ],
                    index > 0 ? null : IMAGE_TYPE_PRODUCT_256w_256h
                );

                image.src = image.url;
                delete image.url;

                return image;
            }),
            brand: product.brandName,
            name: product.nameDisplay,
            url: product.url,
            color: COLORS[product.values.main_color?.value.code],
            price: {
                final: product.price.promotional.formatted,
                regular: priceRegular,
                minimal: product.price.omnibus?.formatted || '',
                isDiscounted,
                isCentsEnabled,
                showOmnibus,
                showPrevious,
            },
            badges: Object.values(product.badges).map(({ code, label }) => ({
                type: BADGE_VARIANTS_MAP[code],
                label,
            })),
            sizes: Object.values(product.variants || {})
                .filter(({ stock_quantity }) => stock_quantity > 0)
                .sort(sortProductVariantsBySortOrder)
                .map(variant =>
                    String(
                        getChosenSizeLabelFromVariant(
                            variant,
                            product.family.variant_dimension
                        )
                    )
                )
                .filter(
                    label =>
                        ![
                            PRODUCT_ONE_SIZE_LABEL,
                            '00',
                            'OS',
                            'NOSIZE',
                        ].includes(label)
                ),
            promoBadge,
        };
    }

    getBrandName(locale) {
        return this.getStringAttributeValueLabel(
            BRAND_ATTRIBUTE_NAME,
            false,
            locale
        );
    }

    getBrandImage(locale = this.locale) {
        const imageType = IMAGE_TYPE_BRAND_154w_66h;

        const brandValue = this.values[BRAND_ATTRIBUTE_NAME];

        const producer = this.getObjectAttributeValueLabel(
            PRODUCER_ATTRIBUTE_NAME,
            false,
            locale
        );

        if (producer && producer.image) {
            const imagePath = producer.image[locale]
                ? producer.image[locale]
                : producer.image;

            const brandUrlKey = brandValue.value.url;

            return this.$imaginator.getImage(
                imagePath,
                brandUrlKey,
                brandUrlKey,
                imageType
            );
        }

        return IMAGE_PLACEHOLDERS[imageType];
    }

    getOccasion(locale) {
        return this.getArrayAttributeValueLabel(
            OCCASION_ATTRIBUTE_NAME,
            false,
            locale
        );
    }

    getPurpose(locale) {
        return this.getArrayAttributeValueLabel(
            PURPOSE_ATTRIBUTE_NAME,
            false,
            locale
        );
    }

    getSexName(locale) {
        return this.getStringAttributeValueLabel(
            SEX_NAME_ATTRIBUTE_NAME,
            false,
            locale
        );
    }

    getStyle(locale) {
        return this.getArrayAttributeValueLabel(
            STYLE_ATTRIBUTE_NAME,
            false,
            locale
        );
    }

    getBrandUrl() {
        const brandValue = this.values[BRAND_ATTRIBUTE_NAME];
        const brandAttribute = this.attributes[
            BRAND_WITH_COLLECTION_ATTRIBUTE_NAME
        ];

        if (!brandValue || !brandAttribute) {
            return null;
        }

        const path = stringifyCategoryUrl({
            categoryUrl: this.$getLocaleConfigByKey(
                DEFAULT_SEARCH_CATEGORY_NAME
            ),
            filters: {
                [brandAttribute.url]: [brandValue.value.url],
            },
        });

        return this.$createCategoryPath(path);
    }

    getColor(locale) {
        const mainColor = this.getStringAttributeValueLabel(
            COLOR_ATTRIBUTE_NAME,
            false,
            locale
        );

        return (
            mainColor ||
            this.getArrayAttributeValueLabel(COLOR_ARRAY_ATTRIBUTE_NAME)[0] ||
            ''
        );
    }

    getColorVariantsLink(locale) {
        return {
            value: this.getStringAttributeValueLabel(
                PRODUCT_GROUP_ASSOCIATED_ATTRIBUTE_NAME,
                false,
                locale
            ),
            url: this.getStringAttributeUrl(
                PRODUCT_GROUP_ASSOCIATED_ATTRIBUTE_NAME,
                false,
                locale
            ),
        };
    }

    getColorVariantsCount() {
        return this.getStringAttributeValueLabel(
            PRODUCT_COLOR_VARIANTS_COUNT_ATTRIBUTE_NAME,
            false
        );
    }

    getFason(locale) {
        return this.getStringAttributeValueLabel(
            FASON_ATTRIBUTE_NAME,
            false,
            locale
        );
    }

    getImageAlt(locale) {
        return `${this.getBrandName(locale)} ${this.getName(locale)}`.trim();
    }

    getImages(types, limit) {
        let images = this.getArrayAttributeValueLabel(IMAGES_ATTRIBUTE_NAME);

        if (limit) {
            images = images.slice(0, limit);
        }

        const categorySex = Product.getMainCategoryUrl(
            DEFAULT_LOCALE,
            this.$getLocaleConfigByKey,
            this.categories
        );

        if (categorySex) {
            images = images.filter(({ values: { plec } }) => {
                if (!plec || !PRODUCT_IMAGE_SEX_TYPES[categorySex]) {
                    return true;
                }

                return PRODUCT_IMAGE_SEX_TYPES[categorySex].includes(
                    plec.toLowerCase()
                );
            });
        }

        return images.map(image => {
            const result = {
                family: image.family,
            };

            types.forEach(imageType => {
                result[imageType] = this.$imaginator.getImage(
                    image.url,
                    this.getUrlKey(),
                    this.getImageAlt(),
                    imageType,
                    DEFAULT_IMAGE_FORMAT,
                    SOURCE_IMAGE_FORMATS
                );
            });

            return result;
        });
    }

    getModel(locale) {
        return this.getStringAttributeValueLabel(
            MODEL_ATTRIBUTE_NAME,
            false,
            locale
        );
    }

    getName(locale) {
        return this.getStringAttributeValueLabel(
            NAME_ATTRIBUTE_NAME,
            false,
            locale
        );
    }

    getShortName(locale) {
        return `${this.getFason(locale)} ${this.getBrandName(locale)}`;
    }

    getShortNameFromAttribute(locale) {
        return this.getStringAttributeValueLabel(
            SHORT_NAME_ATTRIBUTE_NAME,
            false,
            locale
        );
    }

    getNameDisplay(locale) {
        const name = this.getStringAttributeValueLabel(
            NAME_DISPLAY_ATTRIBUTE_NAME,
            false,
            locale
        );

        return name || this.getName(locale);
    }

    getSku(locale) {
        return this.getStringAttributeValueLabel(
            SKU_ATTRIBUTE_NAME,
            false,
            locale
        );
    }

    getPromoAction(locale = this.locale) {
        return this.getObjectAttributeValueLabel(
            STICKER_ATTRIBUTE_NAME,
            false,
            locale
        );
    }

    getPromoSticker() {
        const { omnibus } = this.getPrice();

        if (this.isOmnibusEnabled && !omnibus.amount) {
            return null;
        }

        const { ...originalStickers } = this.getPromoAction();
        const hotDeal = this.getHotDeal();

        const priorityStickers = {};

        if (hotDeal) {
            priorityStickers[HOT_DEAL_ATTRIBUTE_NAME] = hotDeal;
        }

        const sticker = {
            ...priorityStickers,
            ...originalStickers,
        };

        if (Object.keys(sticker).length === 0) {
            return null;
        }

        return getValidPromoSticker({
            sticker,
            sku: this.getSku(),
            locale: this.locale,
            storeViewTimezone: this.storeViewTimezone,
        });
    }

    getUrl(locale) {
        return this.$createProductPath(
            this.getStringAttributeValueLabel(
                URL_KEY_ATTRIBUTE_NAME,
                false,
                locale
            )
        );
    }

    getUrlKey(locale) {
        return this.getArrayAttributeValueLabel(
            URL_KEY_ATTRIBUTE_NAME,
            false,
            locale
        );
    }

    getVideo(locale) {
        const url = this.getStringAttributeValueLabel(
            VIDEO_ATTRIBUTE_NAME,
            false,
            locale
        );

        return getVimeoVideoUrl(url) || getYoutubeVideoUrl(url) || url;
    }

    getBadges(
        locale,
        isGoodPriceBadgeEnabled = false,
        isOccasionBadgeEnabled = false
    ) {
        const badges = {};

        const { badgeType, badgeContent } = this.getDiscountSticker(
            this.isOmnibusEnabled,
            isGoodPriceBadgeEnabled,
            isOccasionBadgeEnabled
        );

        if (badgeType) {
            badges.discount = {
                code: TYPE_DISCOUNT,
                type: badgeType,
                label: badgeContent,
                color: TYPE_COLOR_MAP[badgeType],
            };
        }

        const {
            [NEW_ATTRIBUTE_NAME]: newness,
        } = this.getObjectAttributeValueLabel(
            BADGES_ATTRIBUTE_NAME,
            true,
            locale
        );

        if (newness) {
            badges.newness = { ...newness, code: TYPE_NEW };
        }

        if (this.getIsEcoFriendly()) {
            badges.ecoFriendly = {
                code: TYPE_ECO_FRIENDLY,
                label: 'Eco-friendly',
                color: COLOR_GREEN_LIGHT,
            };
        }

        return Object.fromEntries(
            Object.entries(badges).slice(0, MAX_BADGES_TO_DISPLAY)
        );
    }

    getIsActive(locale) {
        return this.getBooleanAttributeValueLabel(
            ACTIVE_ATTRIBUTE_NAME,
            false,
            locale
        );
    }

    getDiscountSticker(
        isOmnibusEnabled,
        isGoodPriceBadgeEnabled,
        isOccasionBadgeEnabled
    ) {
        const {
            discount,
            promotional: { amount: promotionalAmount },
            regular: { amount: regularAmount },
            omnibus: { amount: omnibusAmount } = {},
        } = this.getPrice();

        return getDiscountBadge({
            discount,
            promotionalAmount,
            regularAmount,
            omnibusAmount,
            isOmnibusEnabled,
            isGoodPriceBadgeEnabled,
            isOccasionBadgeEnabled,
            $t: this.$t.bind(this),
        });
    }

    getPrice(locale = this.locale, currency = this.currency) {
        const regularPrice = this.getObjectAttributeValueLabel(
            PRICE_ATTRIBUTE_NAME,
            true,
            locale,
            currency
        );
        const finalPrice = this.getObjectAttributeValueLabel(
            FINAL_PRICE_ATTRIBUTE_NAME,
            true,
            locale,
            currency
        );
        const omnibusPrice = this.getObjectAttributeValueLabel(
            OMNIBUS_PRICE_ATTRIBUTE_NAME,
            true,
            locale,
            currency
        );

        const {
            amount: regularPriceAmount = 0,
            formatted: regularPriceFormatted = '',
        } = regularPrice;
        const {
            amount: finalPriceAmount = 0,
            formatted: finalPriceFormatted = '',
        } = finalPrice;
        const {
            amount: omnibusPriceAmount = 0,
            formatted: omnibusPriceFormatted = '',
        } = omnibusPrice;

        const discount = this.getDiscount(locale, currency);
        const discountRegular = this.getDiscountRegular(locale, currency);

        // @todo PWA-173 change to data from search
        const {
            amount: discountAmount,
            formatted: discountFormatted,
        } = getDiscountAmountAndFormatted(
            regularPriceAmount,
            finalPriceAmount,
            locale,
            currency
        );

        return buildProductPriceObject(
            {
                amount: regularPriceAmount,
                formatted: regularPriceFormatted,
            },
            { amount: finalPriceAmount, formatted: finalPriceFormatted },
            {
                amount: omnibusPriceAmount,
                formatted: omnibusPriceFormatted,
            },
            {
                discount,
                discountRegular,
                formatted: discountFormatted,
                amount: discountAmount,
            }
        );
    }

    getSeriesName(locale) {
        return this.getStringAttributeValueLabel(
            SERIES_NAME_ATTRIBUTE_NAME,
            false,
            locale
        );
    }

    getSizeType(locale) {
        return this.getStringAttributeValueLabel(
            SIZE_TYPE_NAME_ATTRIBUTE_NAME,
            false,
            locale
        );
    }

    get sexName() {
        return this.getStringAttributeValueLabel(SEX_NAME_ATTRIBUTE_NAME);
    }

    get style() {
        return this.getStringAttributeValueLabel(STYLE_ATTRIBUTE_NAME);
    }

    getTotalStockQuantity() {
        let total = 0;

        const { variants } = this;

        Object.values(variants || {}).forEach(variant => {
            const { stock_quantity: stockQuantity } = variant || {};

            if (typeof stockQuantity === 'number') {
                total += stockQuantity;
            }
        });

        return total;
    }

    getIsOneSize() {
        if (!this.getHasOneVariant()) {
            return false;
        }

        const sizes = Object.values(this.variants || {});

        const { variant_dimension: variantDimension = null } = this.family;

        const [size] = sizes;

        const sizeLabelVariant = getProductVariantLabel(size, variantDimension);

        const sizeLabelProductCard = getProductVariantLabel(
            size,
            PRODUCT_CARD_SIZE_ATTRIBUTE_NAME
        );

        return [sizeLabelVariant, sizeLabelProductCard]
            .filter(sizeLabel => !!sizeLabel)
            .map(sizeLabel => sizeLabel.toLowerCase())
            .includes(PRODUCT_ONE_SIZE_LABEL.toLowerCase());
    }

    getChosenSize() {
        if (this.getHasOneVariant()) {
            return Object.keys(this.variants)[0] || '';
        }

        return '';
    }

    getIsInStock() {
        return this.getTotalStockQuantity() > 0;
    }

    getBooleanAttributeValueLabel(...params) {
        return this.getAttributeValueLabel(...params) || false;
    }

    getStringAttributeValueLabel(...params) {
        return this.getAttributeValueLabel(...params) || '';
    }

    getArrayAttributeValueLabel(...params) {
        return this.getAttributeValueLabel(...params) || [];
    }

    getObjectAttributeValueLabel(...params) {
        return this.getAttributeValueLabel(...params) || {};
    }

    getDiscountValue(attribute, locale, currency) {
        if (!this.$getLocaleConfigByKey(IS_DISCOUNT_ENABLED)) {
            return null;
        }

        const { amount: discount = 0 } = this.getObjectAttributeValueLabel(
            attribute,
            true,
            locale,
            currency
        );

        return parseInt(discount, 10) > 0 ? `${discount}%` : null;
    }

    getDiscountRegular(locale, currency) {
        return this.getDiscountValue(DISCOUNT_ATTRIBUTE_NAME, locale, currency);
    }

    getDiscountOmnibus(locale, currency) {
        return this.getDiscountValue(
            OMNIBUS_DISCOUNT_ATTRIBUTE_NAME,
            locale,
            currency
        );
    }

    getDiscount(locale, currency) {
        return this.$getLocaleConfigByKey(IS_OMNIBUS_STRICT_ENABLED)
            ? this.getDiscountOmnibus(locale, currency)
            : this.getDiscountRegular(locale, currency);
    }

    getIsEcoFriendly() {
        const ecoFriendlyValue = this.getObjectAttributeValueLabel(
            ECO_FRIENDLY_ATTRIBUTE_NAME,
            true
        );

        return ecoFriendlyValue?.code === IS_ECO_FRIENDLY_VALUE_CODE;
    }

    getSizeSuggestion() {
        return this.getStringAttributeValueLabel(
            SIZE_SUGGESTION_ATTRIBUTE_NAME
        );
    }

    getIsPremium(locale = this.locale) {
        const producer = this.getObjectAttributeValueLabel(
            PRODUCER_ATTRIBUTE_NAME,
            false,
            locale
        );

        return !!producer?.[PREMIUM_ATTRIBUTE_NAME];
    }

    getHotDeal() {
        const { locale, storeViewTimezone } = this;

        const hotDealAttributeValue =
            this.values[HOT_DEAL_ATTRIBUTE_NAME]?.value[locale] || {};

        return getHotDealPromoSticker({
            hotDealAttributeValue,
            locale,
            storeViewTimezone,
        });
    }

    getRelatedIndexes() {
        return this.associations?.pack || [];
    }

    getStringAttributeUrl(attributeCode) {
        return this.attributes[attributeCode]?.url || '';
    }

    getAttributeValueLabel(
        attributeCode,
        raw = false,
        locale = this.locale,
        currency = this.currency
    ) {
        const productValue = this.values[attributeCode];
        const productAttribute = this.attributes[attributeCode];

        if (!productAttribute || !productValue) {
            return;
        }

        const { localizable, type } = productAttribute;

        if (localizable) {
            if ([ATTRIBUTE_TYPE_TEXT, ATTRIBUTE_TYPE_BOOLEAN].includes(type)) {
                return productValue.value[locale];
            }

            if (type === ATTRIBUTE_TYPE_PRICE_COLLECTION) {
                const { value = {} } = productValue;

                const valueOfLocaleAndCurrency = value?.[locale]?.[currency];

                if (!valueOfLocaleAndCurrency) {
                    return {};
                }

                return raw
                    ? valueOfLocaleAndCurrency
                    : valueOfLocaleAndCurrency.formatted;
            }

            if (type === ATTRIBUTE_TYPE_REFERENCE_ENTITY) {
                const { value = {} } = productValue;

                return value[locale] || {};
            }

            if (type === ATTRIBUTE_TYPE_REFERENCE_ENTITY_COLLECTION) {
                return productValue.value[locale];
            }

            if (type === ATTRIBUTE_TYPE_MULTI_SELECT) {
                const { value = {} } = productValue;

                if (!value[locale]) {
                    return {};
                }

                const localeValue = value[locale];

                return raw
                    ? localeValue
                    : Object.values(localeValue).map(({ label }) => label);
            }
        } else {
            if ([ATTRIBUTE_TYPE_TEXT, ATTRIBUTE_TYPE_NUMBER].includes(type)) {
                return productValue.value;
            }

            if (type === ATTRIBUTE_TYPE_SIMPLE_SELECT) {
                return raw ? productValue.value : productValue.value.label;
            }

            if (type === ATTRIBUTE_TYPE_MULTI_SELECT) {
                if (raw) {
                    return productValue.value;
                }

                const values = productValue.value;

                return Object.keys(values).map(key => {
                    return values[key].label;
                });
            }

            if (type === ATTRIBUTE_TYPE_REFERENCE_ENTITY) {
                return productValue?.value?.values || {};
            }

            if (type === ATTRIBUTE_TYPE_ASSETS_COLLECTION) {
                return productValue.value;
            }
        }
    }

    getIsSponsored() {
        return !!Object.keys(this.sponsorshipDetails).length;
    }

    getAdTechEvents() {
        const {
            click_event_urls: onClickBeacons = [],
            view_event_urls: onViewBeacons = [],
            load_event_urls: onLoadBeacons = [],
        } = this.sponsorshipDetails;

        return {
            onClickBeacons,
            onViewBeacons,
            onLoadBeacons,
        };
    }

    getHasTrueSize() {
        return Object.values(this.variants || {}).some(({ values = {} }) =>
            TRUE_SIZE_TYPES.every(type => Object.keys(values).includes(type))
        );
    }

    getMainImageType() {
        return this.getImages([IMAGE_TYPE_PRODUCT_256w_256h], 1)[0].family;
    }

    getHasOneVariant() {
        return Object.values(this.variants || {}).length === 1;
    }

    static getMainCategoryUrl(locale, $getLocaleConfigByKey, categories = []) {
        return (
            categories[0]?.[1]?.translations?.[locale]?.url ||
            $getLocaleConfigByKey(DEFAULT_SEARCH_CATEGORY_NAME)
        );
    }

    static getCategory(locale, categories = []) {
        if (!categories.length) {
            return null;
        }

        const firstCategories = categories[0] || [];
        const category = firstCategories[firstCategories.length - 1] || null;

        return category?.translations?.[locale] || null;
    }

    static extendProductWithCategoriesData(
        product,
        categories,
        locale,
        $getLocaleConfigByKey
    ) {
        const categorySex = Product.getMainCategoryUrl(
            DEFAULT_LOCALE,
            $getLocaleConfigByKey,
            categories
        );

        const filteredImages = product.images.filter(
            ({ values: { plec } = {} }) => {
                if (!plec || !PRODUCT_IMAGE_SEX_TYPES[categorySex]) {
                    return true;
                }

                return PRODUCT_IMAGE_SEX_TYPES[categorySex].includes(
                    plec.toLowerCase()
                );
            }
        );

        return {
            ...product,
            images: categorySex ? filteredImages : product.images,
            analytics: {
                ...product.analytics,
                categories,
            },
            category: Product.getCategory(locale, categories),
            mainCategoryUrl: Product.getMainCategoryUrl(
                locale,
                $getLocaleConfigByKey,
                categories
            ),
            categories,
        };
    }
}
