<template>
    <div class="header-main-bar">
        <ButtonIcon
            :variant="HAMBURGER_BUTTON_VARIANT"
            :data-test-id="HAMBURGER_MENU_BUTTON"
            class="hamburger-menu-button"
            @click="onHamburgerClick()"
        >
            <Icon :icon="Hamburger" />
        </ButtonIcon>

        <BaseLink :path="mainPagePath">
            <MainLogo :name="logoImportName" />
        </BaseLink>

        <HeaderSearch v-if="!isMobile" class="search-wrapper" />

        <HeaderActions class="actions-wrapper" />

        <component
            :is="headerMenuComponent"
            v-if="isMobile && isHeaderMenuOpen"
            :is-modal-open="isHeaderMenuOpen"
        />
    </div>
</template>

<script>
import { mapState } from 'vuex';

import { LOGO_EOB_IMPORT_NAME } from '@localeConfig/keys';

import { HAMBURGER_MENU_BUTTON } from '@types/AutomaticTestIDs';
import { HAMBURGER_MENU_OPEN_CLICK } from '@analytics-types/Events';

import { HOME_PAGE } from '@router/paths';

import BaseLink from '@atoms/BaseLink/BaseLink';

import MainLogo from '@header-molecules/MainLogo/MainLogo';

import HeaderActions from '@header-organisms/HeaderActions/HeaderActions';
import HeaderSearch from '@header-organisms/HeaderSearch/HeaderSearch';

import { Hamburger } from '@eobuwie-ui/icons/v2/navigation';

import { Icon } from '@eobuwie-ui/components/Icon/v1';
import {
    ButtonIcon,
    BUTTON_ICON_VARIANTS,
} from '@eobuwie-ui/components/ButtonIcon/v1';

export default {
    name: 'HeaderMainBar',

    components: {
        BaseLink,
        MainLogo,
        ButtonIcon,
        Icon,
        HeaderActions,
        HeaderSearch,
    },

    data() {
        return {
            headerMenuComponent: null,
        };
    },

    computed: {
        ...mapState(['isMobile']),

        mainPagePath() {
            return HOME_PAGE;
        },

        logoImportName() {
            return this.$getLocaleConfigByKey(LOGO_EOB_IMPORT_NAME);
        },

        isHeaderMenuOpen() {
            return this.$modals.isOpen('header-menu');
        },
    },

    beforeCreate() {
        this.Hamburger = Hamburger;
        this.HAMBURGER_BUTTON_VARIANT = BUTTON_ICON_VARIANTS.TERTIARY;
        this.HAMBURGER_MENU_BUTTON = HAMBURGER_MENU_BUTTON;
    },

    methods: {
        async onHamburgerClick() {
            if (!this.headerMenuComponent) {
                this.headerMenuComponent = (
                    await import(
                        /* webpackChunkName: "header-menu" */
                        '@header-organisms/HeaderMenu/HeaderMenu'
                    )
                ).default;
            }

            this.$modals.open('header-menu');
            this.$analytics.emit(HAMBURGER_MENU_OPEN_CLICK);
        },
    },
};
</script>

<style scoped lang="scss">
.header-main-bar {
    @apply flex items-center h-ui-13 px-ui-2;

    .hamburger-menu-button {
        @apply flex-shrink-0;
    }

    .actions-wrapper {
        @apply flex-grow flex justify-end;
    }

    .search-wrapper {
        @apply hidden;
    }

    @screen lg {
        @apply px-ui-7 pt-ui-4 pb-ui-0 max-w-[1440px] mx-auto gap-x-ui-7;

        .search-wrapper {
            @apply block flex-grow;
        }

        .actions-wrapper {
            @apply flex-grow-0;
        }

        .hamburger-menu-button {
            @apply hidden;
        }
    }
}
</style>
