import { BADGE_VARIANTS } from '@eobuwie-ui/components/Badge/v1';

export const COLOR_GREEN = 'green';
export const COLOR_GREEN_LIGHT = 'green-light';
export const COLOR_RED = 'red';
export const COLOR_BLACK = 'black';
export const COLOR_WHITE = 'white';
export const COLOR_BLUE = 'blue';
export const COLOR_BLUE_LIGHT = 'blue-light';
export const COLOR_BLUE_LIGHT2 = 'blue-light2';
export const COLOR_PURPLE = 'purple';
export const COLOR_DARK_PURPLE = 'dark-purple';

export const TYPE_OCCASION = 'occasion';
export const TYPE_DISCOUNT = 'discount';
export const TYPE_NEW = 'nowosc';
export const TYPE_ECO_FRIENDLY = 'eco-friendly';
export const TYPE_SOLD_OUT = 'sold-out';
export const TYPE_APP_ONLY = 'app-only';
export const TYPE_GOOD_PRICE = 'good-price';

export const TYPE_COLOR_MAP = {
    [TYPE_OCCASION]: COLOR_RED,
    [TYPE_DISCOUNT]: COLOR_RED,
};
export const COLORS = {
    COLOR_GREEN,
    COLOR_GREEN_LIGHT,
    COLOR_RED,
    COLOR_BLACK,
    COLOR_WHITE,
    COLOR_BLUE,
    COLOR_BLUE_LIGHT,
    COLOR_BLUE_LIGHT2,
    COLOR_PURPLE,
    COLOR_DARK_PURPLE,
};

export const PRICING_DISCOUNT_LABEL_TYPE = 'discount';
export const PRICING_SPECIAL_OFFER_LABEL_TYPE = 'special_offer';
export const PRICING_GOOD_PRICE_LABEL_TYPE = 'good_price';
export const BADGE_VARIANTS_MAP = {
    [TYPE_NEW]: BADGE_VARIANTS.NEW,
    [TYPE_DISCOUNT]: BADGE_VARIANTS.DISCOUNT,
    [TYPE_OCCASION]: BADGE_VARIANTS.DISCOUNT,
    [TYPE_GOOD_PRICE]: BADGE_VARIANTS.DOBRA_CENA,
    [TYPE_ECO_FRIENDLY]: BADGE_VARIANTS.ECO,
    [TYPE_SOLD_OUT]: BADGE_VARIANTS.SOLD_OUT,
    [TYPE_APP_ONLY]: BADGE_VARIANTS.APP_ONLY,
};
