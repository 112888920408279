import { API_CLIENTS } from '@configs/api-clients';
import { getInstanceIdHeader } from '@configs/http-headers';
import { UNAUTHORIZED_CODE } from '@configs/http-codes';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import { CORE_ERROR_DOMAIN } from '@errors/feature-domain-names';

import CfChallangeHandler from '@models/CfChallengeHandler/CfChallengeHandler';

import {
    createAuthorizationHeader,
    handleRefreshTokensAfterUnauthorizedResponse,
} from '@assets/nuxt-http/auth-interceptor';

const TIME_START_NAME = 'timestampStart';

const getNameFromMegatronUrl = url => {
    const [path] = url.split('?');

    return path.split('/').at(-1);
};

export const performanceRequestInterceptor = request => {
    if (process.client) {
        return;
    }

    request.meta = request.meta || {};
    request.meta[TIME_START_NAME] = new Date().getTime();
};

export const performanceResponseInterceptor = (response, store) => {
    if (process.client) {
        return;
    }

    try {
        const { meta, url, method, baseURL } = response.config;
        const start = meta[TIME_START_NAME];

        store.dispatch('addPerformanceEntry', {
            client: API_CLIENTS.MEGATRON,
            timestampStart: start,
            duration: new Date().getTime() - start,
            name: getNameFromMegatronUrl(url),
            data: {
                baseURL,
                url,
                method,
            },
        });
    } catch (e) {
        console.log(e);
    }
};

const handleCloudFlareChallenge = async (
    err,
    context,
    retryHandler,
    helpers
) => {
    if (process.server) {
        return;
    }

    const { $config, $abTests, $errorHandler, $t, store } = helpers;

    if ($abTests.getVariant('dev_cf_ch') !== 'on') {
        return;
    }

    if (!context?.hasCfChallenge) {
        return;
    }

    const { retry, checkIfRetryWasAlreadyMade } = retryHandler;

    if (checkIfRetryWasAlreadyMade()) {
        return;
    }

    const { headers = {} } = err.response;

    if (!CfChallangeHandler.checkForChallengeHeader(headers)) {
        return;
    }

    let cfSiteKey = null;

    try {
        const { cloudFlare } = $config;

        const storeCode = store.getters['config/storeCode'];

        cfSiteKey =
            Array.isArray(cloudFlare) &&
            cloudFlare.find(({ code }) => code === storeCode)?.siteKey;

        if (!cfSiteKey) {
            throw new Error(
                `Getting CF Site Key for storeCode ${storeCode} error ocurred`
            );
        }
    } catch (error) {
        $errorHandler.captureDomainError(CORE_ERROR_DOMAIN, error, {
            [ERROR_ACTION_TAG_NAME]: 'interceptors.cf.challenge',
        });

        return;
    }

    let preClearanceObtained = null;

    try {
        preClearanceObtained = await new CfChallangeHandler(
            cfSiteKey,
            'cf-challenge-wrapper',
            $t('We check whether you are not a robot')
        ).makeChallenge();

        if (preClearanceObtained) {
            return retry();
        }

        throw new Error('Unable to obtain pre-clearance');
    } catch (error) {
        $errorHandler.captureDomainError(CORE_ERROR_DOMAIN, error, {
            [ERROR_ACTION_TAG_NAME]: 'interceptors.cf.challenge',
        });
    }
};

const getGroupedFeatureModulesInterceptors = featureModulesSuccessInterceptors => {
    if (!Array.isArray(featureModulesSuccessInterceptors)) {
        throw new Error(
            '"featureModulesSuccessInterceptors" should be an array'
        );
    }

    return featureModulesSuccessInterceptors.reduce(
        (interceptors, moduleInterceptors) => {
            const {
                request: {
                    success: requestSuccess = null,
                    error: requestError = null,
                } = {},
                response: {
                    success: responseSuccess = null,
                    error: responseError = null,
                } = {},
            } = moduleInterceptors || {};

            if (typeof requestSuccess === 'function') {
                interceptors.request.success.push(requestSuccess);
            }

            if (typeof requestError === 'function') {
                interceptors.request.error.push(requestError);
            }

            if (typeof responseSuccess === 'function') {
                interceptors.response.success.push(responseSuccess);
            }

            if (typeof responseError === 'function') {
                interceptors.response.error.push(responseError);
            }

            return interceptors;
        },
        {
            request: { success: [], error: [] },
            response: { success: [], error: [] },
        }
    );
};

export const getInterceptors = (
    shouldMeasurePerformance,
    helpers,
    featureModulesSuccessInterceptors = []
) => {
    const { store, $cookies, $correlation } = helpers;

    const {
        request: modulesRequestInterceptors,
        response: modulesResponseInterceptors,
    } = getGroupedFeatureModulesInterceptors(featureModulesSuccessInterceptors);

    const interceptors = {
        request: {
            success: async (request, context) => {
                const {
                    useAuthorization = false,
                    shouldAddXInstanceIdHeader = false,
                } = context;

                request.headers = {
                    ...request.headers,
                    ...$correlation.getHeaders(),
                };

                if (shouldAddXInstanceIdHeader) {
                    request.headers = {
                        ...request.headers,
                        ...getInstanceIdHeader($cookies, store),
                    };
                }

                if (useAuthorization) {
                    request.headers = {
                        ...request.headers,
                        ...(await createAuthorizationHeader(helpers)),
                    };
                }

                if (shouldMeasurePerformance) {
                    performanceRequestInterceptor(request);
                }

                modulesRequestInterceptors.success.forEach(interceptor =>
                    interceptor(request, context)
                );
            },
        },
    };

    interceptors.response = {
        success: response => {
            if (shouldMeasurePerformance) {
                performanceResponseInterceptor(response, store);
            }
        },

        error: async (error, context, retryHandler) => {
            if (
                error?.response?.status === UNAUTHORIZED_CODE &&
                !retryHandler.checkIfRetryWasAlreadyMade()
            ) {
                const newAccessToken = await handleRefreshTokensAfterUnauthorizedResponse(
                    helpers
                );

                if (newAccessToken) {
                    const { config } = error;

                    return retryHandler.retry({
                        ...config,
                        headers: {
                            ...config.headers,
                            authorization: `Bearer ${newAccessToken}`,
                        },
                    });
                }
            }

            modulesResponseInterceptors.error.forEach(interceptor =>
                interceptor(error, context)
            );

            return handleCloudFlareChallenge(
                error,
                context,
                retryHandler,
                helpers
            );
        },
    };

    return interceptors;
};
