import { HTTP_CLIENT_ENDPOINT_NAME_ADS } from '@configs/http-client';
import {
    NOT_FOUND_HTTP_CODE,
    REQUEST_TIMEOUT_HTTP_CODE,
} from '@configs/http-codes';
import { getInstanceIdHeader } from '@configs/http-headers';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import { getAbtestHeader } from '@assets/ab-test';

import { METHOD_POST, NO_REFERRER, X_LOCALE, HEADERS_CONFIG } from '../common';

const ENDPOINT_PARAMETER = { endpoint: HTTP_CLIENT_ENDPOINT_NAME_ADS };
const UNKNOWN_ERROR = 'UNKNOWN_ERROR';

const ERROR_TAGS = {
    DISPLAY: 'getDisplay',
    SPONSORED_BANNER: 'getSponsoredBanner',
    DISPLAY_WITH_POSITIONS: 'getDisplayWithPositions',
};
const getErrorTags = action => ({
    [ERROR_ACTION_TAG_NAME]: `services.adTech.${action}`,
});

const emptyDisplayResponse = () => ({
    display: [],
});

const emptyDisplayWithPositionsResponse = () => ({
    display: {},
});

export default ({
    httpClient,
    helpers: { $cookies, $store, abTests },
    $errorHandler,
}) => ({
    notifyBeacon: ({ beacon, clickLabel = '' }) => {
        const shouldPassInstanceHeader = !beacon.includes('criteo.com');
        const paramSeparator = beacon.includes('?') ? '&' : '?';

        const beaconUrl = clickLabel
            ? `${beacon}${paramSeparator}label=${clickLabel}`
            : beacon;

        fetch(beaconUrl, {
            method: METHOD_POST,
            referrerPolicy: NO_REFERRER,
            headers: {
                ...(shouldPassInstanceHeader &&
                    getInstanceIdHeader($cookies, $store)),
            },
        }).catch(() => {});
    },

    getDisplay: async ({ payload, locale }) => {
        try {
            const { data, error, status } = await httpClient.post('/display', {
                ...ENDPOINT_PARAMETER,
                payload,
                headers: {
                    [X_LOCALE]: locale,
                    ...HEADERS_CONFIG,
                    ...getInstanceIdHeader($cookies, $store),
                    ...getAbtestHeader(abTests),
                    version: 2,
                },
            });

            if (!error) {
                return data;
            }

            if (status === REQUEST_TIMEOUT_HTTP_CODE) {
                return emptyDisplayResponse();
            }

            if (status !== NOT_FOUND_HTTP_CODE) {
                $errorHandler.captureError(
                    new Error(error?.message || UNKNOWN_ERROR, {
                        cause: error,
                    }),
                    getErrorTags(ERROR_TAGS.DISPLAY),
                    {
                        payload,
                        data,
                        error,
                        status,
                    }
                );
            }

            return emptyDisplayResponse();
        } catch (err) {
            $errorHandler.captureError(err, getErrorTags(ERROR_TAGS.DISPLAY));

            return emptyDisplayResponse();
        }
    },

    getDisplayWithPositions: async ({ payload, locale }) => {
        try {
            const { data, error, status } = await httpClient.post(
                '/display-with-positions',
                {
                    ...ENDPOINT_PARAMETER,
                    payload,
                    headers: {
                        [X_LOCALE]: locale,
                        ...HEADERS_CONFIG,
                        ...getInstanceIdHeader($cookies, $store),
                        ...getAbtestHeader(abTests),
                        version: 2,
                    },
                }
            );

            if (!error) {
                return data;
            }

            if (status === REQUEST_TIMEOUT_HTTP_CODE) {
                return emptyDisplayWithPositionsResponse();
            }

            if (status !== NOT_FOUND_HTTP_CODE) {
                $errorHandler.captureError(
                    new Error(error?.message || UNKNOWN_ERROR, {
                        cause: error,
                    }),
                    getErrorTags(ERROR_TAGS.DISPLAY_WITH_POSITIONS),
                    {
                        payload,
                        data,
                        error,
                        status,
                    }
                );
            }

            return emptyDisplayWithPositionsResponse();
        } catch (err) {
            $errorHandler.captureError(
                err,
                getErrorTags(ERROR_TAGS.DISPLAY_WITH_POSITIONS)
            );

            return emptyDisplayWithPositionsResponse();
        }
    },

    getStaticBanner: async ({ payload }) => {
        try {
            const { data, error, status } = await httpClient.get(
                '/static-banners',
                {
                    ...ENDPOINT_PARAMETER,
                    payload,
                    headers: {
                        ...getAbtestHeader(abTests),
                    },
                }
            );

            if (!error) {
                return data;
            }

            if (status === REQUEST_TIMEOUT_HTTP_CODE) {
                return null;
            }

            if (status !== NOT_FOUND_HTTP_CODE) {
                $errorHandler.captureError(
                    new Error(error?.message || UNKNOWN_ERROR, {
                        cause: error,
                    }),
                    getErrorTags(ERROR_TAGS.SPONSORED_BANNER),
                    {
                        payload,
                        data,
                        error,
                        status,
                    }
                );
            }

            return null;
        } catch (err) {
            $errorHandler.captureError(
                err,
                getErrorTags(ERROR_TAGS.SPONSORED_BANNER)
            );

            return null;
        }
    },
});
