<template>
    <component
        :is="linkComponent"
        :title="category.name"
        :path="category.path"
        :data-test-id="NAVIGATION_NESTED_ITEM_LINK"
        class="nav-i"
        @click.native="onNavigationItemClick()"
        @click.native.middle="emitAnalytics()"
    >
        <div v-if="category.icon" class="icon-wrapper">
            <BasePicture
                v-if="image.url"
                :width="image.width"
                :height="image.height"
                :src="image.url"
                :alt="image.alt"
                :sources="image.sources"
                :is-lazy="true"
                class="icon"
            />
        </div>

        <slot>
            <span class="truncate">{{ category.name }}</span>
        </slot>
    </component>
</template>

<script>
import { createNamespacedHelpers, mapState } from 'vuex';
import {
    DEFAULT_IMAGE_FORMAT,
    MAGENTO_IMAGE_TYPES as IMAGE_TYPES,
    SOURCE_IMAGE_FORMATS,
} from '@types/Image';
import { NAVIGATION_NESTED_ITEM_LINK } from '@types/AutomaticTestIDs';

import { HEADER_MEGA_MENU_SELECTION } from '@analytics-types/Events';

import { isHttpLink } from '@assets/link';

import BaseLink from '@atoms/BaseLink/BaseLink';
import BaseLinkStatic from '@atoms/BaseLinkStatic/BaseLinkStatic';
import BasePicture from '@atoms/BasePicture/BasePicture';

const {
    mapState: mapNavigationState,
    mapActions: mapNavigationActions,
} = createNamespacedHelpers('navigation');

export default {
    name: 'NavigationNestedItem',

    components: {
        BaseLink,
        BaseLinkStatic,
        BasePicture,
    },

    props: {
        category: {
            type: Object,
            required: true,
        },
    },

    setup() {
        return {
            NAVIGATION_NESTED_ITEM_LINK,
        };
    },

    data() {
        return {
            image: {
                url: '',
                alt: '',
            },
        };
    },

    computed: {
        ...mapState(['isMobile']),
        ...mapNavigationState(['isDesktopMenuOpen']),

        shouldLoadImages() {
            return this.isMobile
                ? this.$modals.isOpen('header-menu')
                : this.isDesktopMenuOpen;
        },

        isHttpLink() {
            return isHttpLink(this.category.path);
        },

        linkComponent() {
            if (!this.category.path) {
                return 'div';
            }

            return this.isHttpLink ? BaseLinkStatic : BaseLink;
        },
    },

    watch: {
        shouldLoadImages(shouldLoad) {
            if (!shouldLoad) {
                return;
            }

            this.loadImage();
        },
    },

    async mounted() {
        await this.$nextTick();

        if (this.shouldLoadImages) {
            this.loadImage();
        }
    },

    methods: {
        ...mapNavigationActions(['setMenuDesktopIsOpen']),

        onNavigationItemClick() {
            this.emitAnalytics();
        },

        emitAnalytics() {
            this.$analytics.emit(HEADER_MEGA_MENU_SELECTION, {
                url: this.category.path,
            });
        },

        loadImage() {
            const { icon, name } = this.category;

            if (!icon) {
                return;
            }

            this.image = this.$imaginator.getImage(
                icon,
                name,
                name,
                IMAGE_TYPES.mag_navigation.name,
                DEFAULT_IMAGE_FORMAT,
                SOURCE_IMAGE_FORMATS
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.nav-i {
    @apply text-ui-text-primary-default flex items-center my-ui-1;
    @apply relative cursor-pointer px-ui-4;
    @apply h-6 truncate font-ui-body-m;

    &:hover {
        @apply no-underline;

        &:after {
            @apply absolute bottom-[-1px] w-full h-ui-1 z-ui-1 content-[''];
            @apply bg-ui-container-primary-default;
        }
    }

    .icon-wrapper {
        @apply h-full w-6 mr-3 rounded-50 bg-gray4;
    }

    .icon {
        @apply h-full rounded-50;
    }

    :deep(.image) {
        @apply rounded-50;
    }
}

@screen lg {
    .nav-t,
    .nav-i {
        @apply px-0;
    }
}
</style>
