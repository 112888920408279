<template>
    <div class="main">
        <template v-if="isWebViewMobile">
            <main>
                <div class="wrapper">
                    <nuxt />
                </div>
            </main>
        </template>

        <template v-else>
            <MarketingBarDataProvider />

            <HeaderWrapper />
            <main>
                <ContainerContentWithSidebar
                    :is-sidebar-visible-on-mobile="true"
                    class="page"
                >
                    <template #sidebar>
                        <SidebarMenu :is-mobile="isMobile">
                            <StaticHtmlLinkHandler>
                                <StaticBlock
                                    v-if="
                                        sidebarStaticBlocks.staticBlockSidebarMenu
                                    "
                                    :content="
                                        sidebarStaticBlocks
                                            .staticBlockSidebarMenu.content
                                    "
                                    class="sidebar-menu"
                                />
                            </StaticHtmlLinkHandler>
                        </SidebarMenu>
                    </template>
                    <div class="wrapper">
                        <nuxt />
                    </div>
                </ContainerContentWithSidebar>
                <NewsletterSectionWrapper />
            </main>
            <Messages />
            <BaseFooter />
            <ProductExitModalWrapper :is-mobile="isMobile" />
            <ChatWrapper />
            <GlobalModals />
        </template>
        <SmartBannerWrapper />
    </div>
</template>

<script>
import { mapState, createNamespacedHelpers } from 'vuex';

import { PSB_SIDEBAR_MENU } from '@configs/sidebar';

import StaticBlock from '@atoms/StaticBlock/StaticBlock';

import ContainerContentWithSidebar from '@molecules/ContainerContentWithSidebar/ContainerContentWithSidebar';
import Messages from '@molecules/Messages/Messages';
import StaticHtmlLinkHandler from '@molecules/StaticHtmlLinkHandler/StaticHtmlLinkHandler';
import NewsletterSectionWrapper from '@molecules/NewsletterSectionWrapper/NewsletterSectionWrapper';
import ChatWrapper from '@molecules/ChatWrapper/ChatWrapper';
import SmartBannerWrapper from '@molecules/SmartBanner/SmartBannerWrapper';

import BaseFooter from '@organisms/BaseFooter/BaseFooter';
import SidebarMenu from '@organisms/SidebarMenu/SidebarMenu';
import ProductExitModalWrapper from '@organisms/ProductExitModalWrapper/ProductExitModalWrapper';
import GlobalModals from '@organisms/GlobalModals/GlobalModals';
import HeaderWrapper from '@header-organisms/HeaderWrapper/HeaderWrapper';
import MarketingBarDataProvider from '@marketing-bar-components/organisms/MarketingBarDataProvider/MarketingBarDataProvider';

import BaseLayout from './mixins/BaseLayout';
import MobileWebView from './mixins/MobileWebView';

const { mapGetters: mapCmsBlockGetters } = createNamespacedHelpers('cmsBlock');

export default {
    name: 'CmsLayout',

    components: {
        HeaderWrapper,
        BaseFooter,
        Messages,
        StaticHtmlLinkHandler,
        ContainerContentWithSidebar,
        SidebarMenu,
        StaticBlock,
        NewsletterSectionWrapper,
        ProductExitModalWrapper,
        ChatWrapper,
        GlobalModals,
        MarketingBarDataProvider,
        SmartBannerWrapper,
    },

    mixins: [BaseLayout, MobileWebView],

    computed: {
        ...mapState(['isMobile']),
        ...mapCmsBlockGetters(['staticBlockById']),

        sidebarStaticBlocks() {
            return {
                staticBlockSidebarMenu:
                    this.staticBlockById(PSB_SIDEBAR_MENU) || {},
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.page {
    &:deep() {
        .sidebar-menu {
            .static-pages-menu {
                .menu-button {
                    @apply hidden;
                }

                .menu-content {
                    .title,
                    .close {
                        @apply hidden;
                    }
                    .subtitle {
                        @apply font-bold text-text text-m leading-m;
                    }

                    .links-list {
                        @apply py-3;

                        .menu-link {
                            @apply mb-2;

                            a {
                                @apply text-text;
                            }
                        }
                    }
                }
            }
            .base-paragraph {
                @apply mb-3;
            }

            .text-link {
                @apply text-text;
            }
        }
    }
}
</style>
