import { ERROR_ACTION_TAG_NAME } from '@types/Errors';
import { DEFAULT_REVIEWS_LIST_SIZE, DATE_SORT } from '@configs/reviews';

export const createReview = (content, name, ratings, ratingCodes) => {
    const review = {
        content,
        name,
        ratings: [],
    };

    ratingCodes.forEach(code => {
        review.ratings.push({
            code,
            value: parseInt(ratings, 10),
        });
    });

    return review;
};

export const calculateAverageFromRatings = (ratings = []) => {
    if (!ratings || ratings.length === 0) {
        return 0;
    }

    const average =
        ratings.reduce((total, rating) => {
            return total + rating.value;
        }, 0) / ratings.length;

    return Math.round(average * 10) / 10;
};

export const loadReviews = async ({
    app,
    sku,
    currentPage = 1,
    pageSize = DEFAULT_REVIEWS_LIST_SIZE,
    dateSort = DATE_SORT,
}) => {
    try {
        const {
            reviews = {},
            errors = [],
        } = await app.$services.product.getProductReviews({
            sku,
            currentPage,
            pageSize,
            dateSort,
        });

        return { reviews, error: !!errors.length };
    } catch (error) {
        app.$errorHandler.captureError(error, {
            [ERROR_ACTION_TAG_NAME]: 'product.getProductReviews',
        });

        return { error: true };
    }
};

export const loadProductsReviewsTotalCountForAnalytics = async (app, skus) => {
    try {
        if (skus.length === 0) {
            return {};
        }

        const {
            reviews = [],
            errors = [],
        } = await app.$services.product.getProductsReviewsTotalCount({
            skus,
        });

        const errorReviews = errors.map(({ sku }) => ({
            sku,
            totalCount: 0,
        }));

        const reviewsWithErrors = [...reviews, ...errorReviews];

        const productsReviewsTotalCount = reviewsWithErrors.reduce(
            (acc, { sku, totalCount = 0 }) => {
                acc[sku] = totalCount;

                return acc;
            },
            {}
        );

        app.$store.dispatch('product/setProductsReviewsTotalCount', {
            productsReviewsTotalCount,
        });

        return productsReviewsTotalCount;
    } catch (err) {
        app.$errorHandler.captureError(err, {
            [ERROR_ACTION_TAG_NAME]: 'product.getProductsReviewsTotalCount',
        });

        return {};
    }
};
