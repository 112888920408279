export const types = {
    SET_CART_ID: 'SET_CART_ID',
    SET_CART_DATA: 'SET_CART_DATA',
    SET_IS_CART_DATA_VALID: 'SET_IS_CART_DATA_VALID',
    SET_IS_CART_DATA_LOADING: 'SET_IS_CART_DATA_LOADING',
    SET_COMPLEX_DATA_PRODUCT_ITEMS: 'SET_COMPLEX_DATA_PRODUCT_ITEMS',
    REMOVE_COMPLEX_DATA_PRODUCT_ITEMS: 'REMOVE_COMPLEX_DATA_PRODUCT_ITEMS',
    SET_FETCHING_PAYMENT_AND_SHIPPING_AFTER_LOGIN:
        'SET_FETCHING_PAYMENT_AND_SHIPPING_AFTER_LOGIN',
    SET_LOADER_TEXT: 'SET_LOADER_TEXT',
    REMOVE_SELECTED_SHIPPING_POINT: 'REMOVE_SELECTED_SHIPPING_POINT',
    SET_PAD_DATA: 'SET_PAD_DATA',
    SET_PAYMENT_TYPE: 'SET_PAYMENT_TYPE',
    SET_IS_FETCHING_CART_WITH_POSTCODE: 'SET_IS_FETCHING_CART_WITH_POSTCODE',
    SET_GIFT_CARD_DATA: 'SET_GIFT_CARD_DATA',
    SET_IS_ADD_TO_CART_PENDING: 'SET_IS_ADD_TO_CART_PENDING',
    SET_GIFT_CARD_ACTIVE: 'SET_GIFT_CARD_ACTIVE',
    SET_GIFT_CARD_ADDITIONAL_PAYMENT_METHOD_CODE:
        'SET_GIFT_CARD_ADDITIONAL_PAYMENT_METHOD_CODE',
};

const mutations = {
    [types.SET_CART_ID](state, cartId) {
        state.cartId = cartId;
    },

    [types.SET_CART_DATA](state, cartData) {
        if (cartData) {
            state.cartData = { ...state.cartData, ...cartData };
        } else {
            state.cartData = cartData;
        }
    },

    [types.SET_IS_CART_DATA_VALID](state, isCartDataValid) {
        state.isCartDataValid = isCartDataValid;
    },

    [types.SET_IS_CART_DATA_LOADING](state, isCartDataLoading) {
        state.isCartDataLoading = isCartDataLoading;
    },

    [types.SET_COMPLEX_DATA_PRODUCT_ITEMS](state, complexDataProductItems) {
        state.complexProductDataItems = [
            ...state.complexProductDataItems,
            ...complexDataProductItems,
        ];
    },

    [types.REMOVE_COMPLEX_DATA_PRODUCT_ITEMS](state, skusToRemove) {
        state.complexProductDataItems = state.complexProductDataItems.filter(
            complexProduct => !skusToRemove.includes(complexProduct.id)
        );
    },

    [types.SET_FETCHING_PAYMENT_AND_SHIPPING_AFTER_LOGIN](state, shouldFetch) {
        state.shouldFetchPaymentAndShippingAfterLogin = shouldFetch;
    },

    [types.SET_LOADER_TEXT](state, text) {
        state.loaderText = text;
    },

    [types.REMOVE_SELECTED_SHIPPING_POINT](state, pointData) {
        state.cartData.extCart.shipping_point = pointData;
    },

    [types.SET_PAD_DATA](state, padData) {
        state.padData = padData;
    },

    [types.SET_PAYMENT_TYPE](state, paymentType) {
        state.selectedPaymentType = paymentType;
    },
    [types.SET_IS_FETCHING_CART_WITH_POSTCODE](
        state,
        isFetchingCartWithPostcode
    ) {
        state.isFetchingCartWithPostcode = isFetchingCartWithPostcode;
    },

    [types.SET_GIFT_CARD_DATA](state, cardData) {
        state.giftCardData = cardData;
    },

    [types.SET_IS_ADD_TO_CART_PENDING](state, isAddToCartPending) {
        state.isAddToCartPending = isAddToCartPending;
    },

    [types.SET_GIFT_CARD_ACTIVE](state, isActive) {
        state.giftCardData = { ...state.giftCardData, isActive };
    },

    [types.SET_GIFT_CARD_ADDITIONAL_PAYMENT_METHOD_CODE](
        state,
        additionalPaymentMethodCode
    ) {
        state.giftCardData = {
            ...state.giftCardData,
            additionalPaymentMethodCode,
        };
    },
};

export default mutations;
