export const CMS_COMPONENTS_PREFIX = 'Cms';

export const CMS_BASE_PARAGRAPH = 'CmsBaseParagraph';
export const CMS_TEXT = 'CmsText';
export const CMS_BASE_HEADING = 'CmsBaseHeading';
export const CMS_BASE_LINK = 'CmsBaseLink';
export const CMS_CONTAINER_CONTENT = 'CmsContainerContent';
export const CMS_CONTAINER_CONTENT_WITH_SIDEBAR =
    'CmsContainerContentWithSidebar';
export const CMS_BASE_LABEL = 'CmsBaseLabel';
export const CMS_BASE_BUTTON = 'CmsBaseButton';
export const CMS_BASE_TAG = 'CmsBaseTag';
export const CMS_PRODUCT_CARD = 'CmsProductCard';
export const CMS_BASE_BADGE = 'CmsBaseBadge';
export const CMS_BASE_MESSAGE = 'CmsBaseMessage';
export const CMS_LIST = 'CmsList';
export const CMS_LIST_ITEM = 'CmsListItem';
export const CMS_BLOCK_IMPORT = 'CmsBlockImport';
export const CMS_PAGE = 'CmsPage';
export const CMS_MARKETING_DISCOUNT_BANNER = 'CmsMarketingDiscountBanner';
export const CMS_MARKETING_BANNER = 'CmsMarketingBanner';
export const CMS_MARKETING_DISPLAY_WITH_IFRAME =
    'CmsMarketingDisplayWithIframe';
export const CMS_MARKETING_BANNER_HONE = 'CmsMarketingBannerHOne';
export const CMS_MARKETING_HERO_BLOCK = 'CmsMarketingHeroBlock';
export const CMS_MARKETING_HERO_BLOCK_IMAGE = 'CmsMarketingHeroBlockImage';
export const CMS_MARKETING_HERO_BLOCK_VIDEO = 'CmsMarketingHeroBlockVideo';
export const CMS_RECOMMENDED_PRODUCTS_SLIDER_WRAPPER =
    'CmsRecommendedProductsSliderWrapper';
export const CMS_RECENTLY_VIEWED_PRODUCTS_SLIDER_WRAPPER =
    'CmsRecentlyViewedProductsSliderWrapper';
export const CMS_SPONSORED_SLIDER_WRAPPER = 'CmsSponsoredSliderWrapper';
export const CMS_MARKETING_CARDS_SLIDER = 'CmsMarketingCardsSlider';
export const CMS_MARKETING_CARDS_SLIDER_SLIDE = 'CmsMarketingCardsSliderSlide';
export const CMS_MARKETING_BRANDS_SLIDER = 'CmsMarketingBrandsSlider';
export const CMS_MARKETING_BRANDS_SLIDER_SLIDE =
    'CmsMarketingBrandsSliderSlide';
export const CMS_MARKETING_BLOG = 'CmsMarketingBlog';
export const CMS_MARKETING_CELEBRITY_SLIDER_SLIDE =
    'CmsMarketingCelebritySliderSlide';
export const CMS_MARKETING_CELEBRITY_SLIDER = 'CmsMarketingCelebritySlider';
export const CMS_MARKETING_INFLUENCER_SLIDER = 'CmsMarketingInfluencerSlider';
export const CMS_MARKETING_INFLUENCER_SLIDER_SLIDE =
    'CmsMarketingInfluencerSliderSlide';
export const CMS_MARKETING_POPULAR_CATEGORIES = 'CmsMarketingPopularCategories';
export const CMS_MARKETING_POPULAR_CATEGORY = 'CmsMarketingPopularCategory';
export const CMS_MARKETING_PROMO_CATEGORIES = 'CmsMarketingPromoCategories';
export const CMS_MARKETING_PROMO_CATEGORIES_SLIDER =
    'CmsMarketingPromoCategoriesSlider';
export const CMS_MARKETING_PROMO_CATEGORIES_SLIDER_ITEM =
    'CmsMarketingPromoCategoriesSliderItem';
export const CMS_GRID_COLUMNS = 'CmsGridColumns';
export const CMS_GRID_COLUMN = 'CmsGridColumn';
export const CMS_HELP_CENTER_CARD_LIST = 'CmsHelpCenterCardList';
export const CMS_HELP_CENTER_CARD_LINK = 'CmsHelpCenterCardLink';
export const CMS_HELP_CENTER_CARD = 'CmsHelpCenterCard';
export const CMS_SIDE_MENU = 'CmsSideMenu';
export const CMS_SIDE_MENU_ITEM = 'CmsSideMenuItem';
export const CMS_FAQ_ACCORDION = 'CmsFaqAccordion';
export const CMS_HELP_CENTER_CONTAINER = 'CmsHelpCenterContainer';
export const CMS_HELP_CENTER_HEADING = 'CmsHelpCenterHeading';
export const CMS_HELP_CENTER_SUBPAGE_HEADING = 'CmsHelpCenterSubpageHeading';
export const CMS_RESPONSIVE_PICTURE = 'CmsResponsivePicture';
export const CMS_CUSTOMER_SERVICE_CHAT = 'CmsCustomerServiceChat';
export const CMS_QUESTION_MARK_TOOLTIP = 'CmsQuestionMarkTooltip';
export const CMS_APP = 'CmsApp';
export const CMS_APP_EOB_PRODUCTS_BANNER_PHOTO_LARGE_CAROUSEL =
    'CmsAppEobProductsBannerPhotoLargeCarousel';
export const CMS_APP_EOB_CATEGORIES = 'CmsAppEobCategories';
export const CMS_APP_EOB_CATEGORY = 'CmsAppEobCategory';
export const CMS_APP_EOB_CAROUSEL_LIST = 'CmsAppEobCarouselList';
export const CMS_APP_EOB_MAIN_BANNER_PROMO_CAROUSEL =
    'CmsAppEobMainBannerPromoCarousel';
export const CMS_APP_EOB_CAROUSEL_LARGE = 'CmsAppEobCarouselLarge';
export const CMS_APP_EOB_BOX_PHOTOS = 'CmsAppEobBoxPhotos';
export const CMS_APP_EOB_BOX_PHOTO = 'CmsAppEobBoxPhoto';
export const CMS_APP_EOB_CAROUSEL_SMALL_SLIDER = 'CmsAppEobCarouselSmallSlider';
export const CMS_APP_EOB_MAIN_BANNER_PROMO_LISTING =
    'CmsAppEobMainBannerPromoListing';
export const CMS_APP_EOB_MAIN_BANNER_PROMO_LISTING_BUTTON_LINK =
    'CmsAppEobMainBannerPromoListingButtonLink';
export const CMS_APP_EOB_TEXT = 'CmsAppEobText';
export const CMS_APP_EOB_TEXT_ADDITIONAL = 'CmsAppEobTextAdditional';
export const CMS_APP_EOB_BOX_BENEFITS = 'CmsAppEobBoxBenefits';
export const CMS_APP_EOB_BOX_BENEFIT = 'CmsAppEobBoxBenefit';
export const CMS_APP_EOB_SMALL_BANNER = 'CmsAppEobSmallBanner';
export const CMS_APP_EOB_PRODUCTS_BANNER_PHOTO_SMALL_CAROUSEL =
    'CmsAppEobProductsBannerPhotoSmallCarousel';
export const CMS_APP_EOB_PRODUCTS_BANNER_PHOTO_TWO_COLUMNS =
    'CmsAppEobProductsBannerPhotoTwoColumns';
export const CMS_APP_EOB_LOYALTY_BANNER = 'CmsAppEobLoyaltyBanner';
export const CMS_MARKETING_SLIDER_SECTION = 'CmsMarketingSliderSection';
export const CMS_MARKETING_SLIDER = 'CmsMarketingSlider';
export const CMS_MARKETING_PRODUCTS_SLIDER = 'CmsMarketingProductsSlider';
export const CMS_MARKETING_VALUE_PROPOSITION_SLIDER_SLIDE =
    'CmsMarketingValuePropositionSliderSlide';
export const CMS_MARKETING_BRANDS_SECTION_SLIDER_SLIDE =
    'CmsMarketingBrandsSectionSliderSlide';
export const CMS_MARKETING_PROMO_SLIDER = 'CmsMarketingPromoSlider';
export const CMS_BANNER_WITH_CODE_AND_CLOCK = 'CmsBannerWithCodeAndClock';
export const CMS_GLOBAL_CONFIG = 'CmsGlobalConfig';
export const CMS_HELP_CENTER_CONFIG = 'CmsHelpCenterConfig';
export const CMS_MARKETING_BANNER_PROMO_LINK = 'CmsMarketingBannerPromoLink';
export const CMS_GOOGLE_ANALYTICS_CONFIG = 'CmsGoogleAnalyticsConfig';
export const CMS_STATIC_BLOCK = 'CmsStaticBlock';
export const CMS_STATIC_BLOCK_WITH_ACCORDIONS = 'CmsStaticBlockWithAccordions';
export const CMS_ZOWIE_FORM = 'CmsZowieForm';
export const CMS_APP_EOB_HEADER_MARKETING_BAR = 'CmsAppEobHeaderMarketingBar';
export const CMS_BRAND_SHOP_LIST = 'CmsBrandShopList';
export const CMS_BRAND_SHOP_ITEM = 'CmsBrandShopItem';
export const CMS_MARKETING_BAR_LINK = 'CmsMarketingBarLink';
export const CMS_MARKETING_BAR_DEFAULT = 'CmsMarketingBarDefault';
export const CMS_MARKETING_BAR_DISCOUNT_CODE = 'CmsMarketingBarDiscountCode';
export const CMS_MARKETING_BAR_TEXT_AND_LINKS = 'CmsMarketingBarTextAndLinks';
export const CMS_MARKETING_BAR_TIMER = 'CmsMarketingBarTimer';
export const CMS_SYNERISE_MAP_CATEGORIES = 'CmsSyneriseMapCategories';
export const CMS_PAYMENT_WIDGET_CONFIG = 'CmsPaymentWidgetConfig';
export const CMS_SMART_BANNER_CONFIG = 'CmsSmartBannerConfig';
export const CMS_APP_TEST_NEW_TYPES = 'CmsAppTestNewTypes';

export const COMPONENT_ALLOWED_CHILDREN = {
    [CMS_CONTAINER_CONTENT]: [
        CMS_BASE_PARAGRAPH,
        CMS_BASE_HEADING,
        CMS_BASE_LINK,
        CMS_TEXT,
        CMS_BASE_LABEL,
        CMS_BASE_BUTTON,
        CMS_BASE_TAG,
        CMS_PRODUCT_CARD,
        CMS_BASE_BADGE,
        CMS_LIST,
        CMS_BLOCK_IMPORT,
        CMS_GRID_COLUMNS,
        CMS_BASE_MESSAGE,
        CMS_MARKETING_PROMO_CATEGORIES_SLIDER,
        CMS_MARKETING_DISPLAY_WITH_IFRAME,
        CMS_CUSTOMER_SERVICE_CHAT,
        CMS_ZOWIE_FORM,
        CMS_STATIC_BLOCK,
        CMS_STATIC_BLOCK_WITH_ACCORDIONS,
    ],
    [CMS_CONTAINER_CONTENT_WITH_SIDEBAR]: [
        CMS_BASE_PARAGRAPH,
        CMS_BASE_HEADING,
        CMS_BASE_LINK,
        CMS_TEXT,
        CMS_BASE_LABEL,
        CMS_BASE_BUTTON,
        CMS_BASE_TAG,
        CMS_PRODUCT_CARD,
        CMS_BASE_BADGE,
        CMS_LIST,
        CMS_BLOCK_IMPORT,
        CMS_SIDE_MENU,
        CMS_BASE_MESSAGE,
        CMS_CUSTOMER_SERVICE_CHAT,
        CMS_ZOWIE_FORM,
        CMS_STATIC_BLOCK,
        CMS_STATIC_BLOCK_WITH_ACCORDIONS,
    ],
    [CMS_BASE_PARAGRAPH]: [
        CMS_TEXT,
        CMS_BASE_LINK,
        CMS_BASE_LABEL,
        CMS_BASE_BADGE,
        CMS_STATIC_BLOCK,
        CMS_STATIC_BLOCK_WITH_ACCORDIONS,
    ],
    [CMS_LIST]: [CMS_LIST_ITEM],
    [CMS_LIST_ITEM]: [CMS_TEXT, CMS_BASE_LINK],
    [CMS_BASE_MESSAGE]: [CMS_TEXT, CMS_BASE_LINK, CMS_QUESTION_MARK_TOOLTIP],
    [CMS_PAGE]: [
        CMS_CONTAINER_CONTENT,
        CMS_CONTAINER_CONTENT_WITH_SIDEBAR,
        CMS_MARKETING_BRANDS_SLIDER,
        CMS_MARKETING_DISCOUNT_BANNER,
        CMS_MARKETING_BANNER,
        CMS_MARKETING_DISPLAY_WITH_IFRAME,
        CMS_MARKETING_BANNER_HONE,
        CMS_MARKETING_HERO_BLOCK,
        CMS_BANNER_WITH_CODE_AND_CLOCK,
        CMS_MARKETING_BLOG,
        CMS_RECOMMENDED_PRODUCTS_SLIDER_WRAPPER,
        CMS_RECENTLY_VIEWED_PRODUCTS_SLIDER_WRAPPER,
        CMS_SPONSORED_SLIDER_WRAPPER,
        CMS_MARKETING_CARDS_SLIDER,
        CMS_MARKETING_CELEBRITY_SLIDER,
        CMS_MARKETING_INFLUENCER_SLIDER,
        CMS_MARKETING_POPULAR_CATEGORIES,
        CMS_HELP_CENTER_CONTAINER,
        CMS_APP,
        CMS_MARKETING_SLIDER_SECTION,
        CMS_MARKETING_PROMO_SLIDER,
        CMS_GLOBAL_CONFIG,
        CMS_HELP_CENTER_CONFIG,
        CMS_MARKETING_BANNER_PROMO_LINK,
        CMS_GOOGLE_ANALYTICS_CONFIG,
        CMS_STATIC_BLOCK,
        CMS_STATIC_BLOCK_WITH_ACCORDIONS,
        CMS_ZOWIE_FORM,
        CMS_BRAND_SHOP_LIST,
        CMS_MARKETING_BAR_DEFAULT,
        CMS_MARKETING_BAR_DISCOUNT_CODE,
        CMS_MARKETING_BAR_TEXT_AND_LINKS,
        CMS_MARKETING_BAR_TIMER,
        CMS_SYNERISE_MAP_CATEGORIES,
        CMS_PAYMENT_WIDGET_CONFIG,
        CMS_SMART_BANNER_CONFIG,
        CMS_APP_TEST_NEW_TYPES,
    ],
    [CMS_MARKETING_INFLUENCER_SLIDER]: [CMS_MARKETING_INFLUENCER_SLIDER_SLIDE],
    [CMS_MARKETING_CARDS_SLIDER]: [CMS_MARKETING_CARDS_SLIDER_SLIDE],
    [CMS_MARKETING_BRANDS_SLIDER]: [CMS_MARKETING_BRANDS_SLIDER_SLIDE],
    [CMS_MARKETING_CELEBRITY_SLIDER]: [CMS_MARKETING_CELEBRITY_SLIDER_SLIDE],
    [CMS_MARKETING_POPULAR_CATEGORIES]: [CMS_MARKETING_POPULAR_CATEGORY],
    [CMS_MARKETING_HERO_BLOCK]: [
        CMS_MARKETING_HERO_BLOCK_IMAGE,
        CMS_MARKETING_HERO_BLOCK_VIDEO,
    ],
    [CMS_GRID_COLUMNS]: [CMS_GRID_COLUMN],
    [CMS_GRID_COLUMN]: [
        CMS_BASE_PARAGRAPH,
        CMS_BASE_HEADING,
        CMS_BASE_LINK,
        CMS_TEXT,
        CMS_BASE_LABEL,
        CMS_BASE_BUTTON,
        CMS_LIST,
        CMS_BASE_MESSAGE,
    ],
    [CMS_HELP_CENTER_CARD_LIST]: [CMS_HELP_CENTER_CARD],
    [CMS_HELP_CENTER_CARD]: [CMS_HELP_CENTER_CARD_LINK],
    [CMS_SIDE_MENU]: [CMS_SIDE_MENU_ITEM],
    [CMS_HELP_CENTER_CONTAINER]: [
        CMS_SIDE_MENU,
        CMS_HELP_CENTER_HEADING,
        CMS_HELP_CENTER_SUBPAGE_HEADING,
        CMS_FAQ_ACCORDION,
        CMS_HELP_CENTER_CARD_LIST,
        CMS_BASE_PARAGRAPH,
        CMS_BASE_HEADING,
        CMS_BASE_LINK,
        CMS_TEXT,
        CMS_BASE_LABEL,
        CMS_BASE_BUTTON,
        CMS_BASE_TAG,
        CMS_LIST,
        CMS_BLOCK_IMPORT,
        CMS_RESPONSIVE_PICTURE,
        CMS_BASE_MESSAGE,
    ],
    [CMS_FAQ_ACCORDION]: [
        CMS_BASE_PARAGRAPH,
        CMS_BASE_HEADING,
        CMS_BASE_LINK,
        CMS_TEXT,
        CMS_BASE_LABEL,
        CMS_BASE_BUTTON,
        CMS_BASE_TAG,
        CMS_LIST,
        CMS_BLOCK_IMPORT,
        CMS_RESPONSIVE_PICTURE,
    ],
    [CMS_APP]: [
        CMS_APP_EOB_CATEGORIES,
        CMS_APP_EOB_CAROUSEL_LIST,
        CMS_APP_EOB_MAIN_BANNER_PROMO_CAROUSEL,
        CMS_APP_EOB_CAROUSEL_LARGE,
        CMS_APP_EOB_BOX_PHOTOS,
        CMS_APP_EOB_CAROUSEL_SMALL_SLIDER,
        CMS_APP_EOB_MAIN_BANNER_PROMO_LISTING,
        CMS_APP_EOB_TEXT,
        CMS_APP_EOB_BOX_BENEFITS,
        CMS_APP_EOB_SMALL_BANNER,
        CMS_APP_EOB_PRODUCTS_BANNER_PHOTO_LARGE_CAROUSEL,
        CMS_APP_EOB_PRODUCTS_BANNER_PHOTO_SMALL_CAROUSEL,
        CMS_APP_EOB_PRODUCTS_BANNER_PHOTO_TWO_COLUMNS,
        CMS_APP_EOB_HEADER_MARKETING_BAR,
        CMS_APP_EOB_LOYALTY_BANNER,
    ],
    [CMS_APP_EOB_CATEGORIES]: [CMS_APP_EOB_CATEGORY, CMS_APP_EOB_TEXT],
    [CMS_APP_EOB_BOX_PHOTOS]: [CMS_APP_EOB_BOX_PHOTO, CMS_APP_EOB_TEXT],
    [CMS_APP_EOB_CAROUSEL_LARGE]: [CMS_APP_EOB_TEXT],
    [CMS_APP_EOB_CAROUSEL_LIST]: [CMS_APP_EOB_TEXT],
    [CMS_APP_EOB_CAROUSEL_SMALL_SLIDER]: [CMS_APP_EOB_TEXT],
    [CMS_APP_EOB_PRODUCTS_BANNER_PHOTO_LARGE_CAROUSEL]: [CMS_APP_EOB_TEXT],
    [CMS_APP_EOB_PRODUCTS_BANNER_PHOTO_SMALL_CAROUSEL]: [CMS_APP_EOB_TEXT],
    [CMS_APP_EOB_PRODUCTS_BANNER_PHOTO_TWO_COLUMNS]: [CMS_APP_EOB_TEXT],
    [CMS_APP_EOB_MAIN_BANNER_PROMO_LISTING]: [
        CMS_APP_EOB_MAIN_BANNER_PROMO_LISTING_BUTTON_LINK,
        CMS_APP_EOB_TEXT,
    ],
    [CMS_APP_EOB_BOX_BENEFITS]: [CMS_APP_EOB_BOX_BENEFIT, CMS_APP_EOB_TEXT],
    [CMS_APP_EOB_TEXT]: [CMS_APP_EOB_TEXT_ADDITIONAL],
    [CMS_QUESTION_MARK_TOOLTIP]: [CMS_BLOCK_IMPORT],
    [CMS_BANNER_WITH_CODE_AND_CLOCK]: [CMS_BASE_LINK, CMS_TEXT],
    [CMS_MARKETING_SLIDER_SECTION]: [
        CMS_MARKETING_SLIDER,
        CMS_MARKETING_PRODUCTS_SLIDER,
        CMS_MARKETING_PROMO_CATEGORIES,
        CMS_MARKETING_PROMO_CATEGORIES_SLIDER,
    ],
    [CMS_MARKETING_PROMO_CATEGORIES]: [CMS_MARKETING_PROMO_CATEGORIES_SLIDER],
    [CMS_MARKETING_PROMO_CATEGORIES_SLIDER]: [
        CMS_MARKETING_PROMO_CATEGORIES_SLIDER_ITEM,
    ],
    [CMS_MARKETING_SLIDER]: [
        CMS_MARKETING_VALUE_PROPOSITION_SLIDER_SLIDE,
        CMS_MARKETING_BRANDS_SECTION_SLIDER_SLIDE,
    ],
    [CMS_MARKETING_BANNER_HONE]: [CMS_MARKETING_PRODUCTS_SLIDER],
    [CMS_MARKETING_PROMO_SLIDER]: [CMS_MARKETING_PRODUCTS_SLIDER],
    [CMS_STATIC_BLOCK_WITH_ACCORDIONS]: [CMS_STATIC_BLOCK, CMS_ZOWIE_FORM],
    [CMS_BRAND_SHOP_LIST]: [CMS_BRAND_SHOP_ITEM],
    [CMS_MARKETING_BAR_TEXT_AND_LINKS]: [CMS_MARKETING_BAR_LINK],
};

export const COMPONENT_TEXTAREA_PROPS = {
    [CMS_TEXT]: ['text'],
    [CMS_STATIC_BLOCK]: ['content'],
    [CMS_STATIC_BLOCK_WITH_ACCORDIONS]: ['content'],
};

export const COMPONENT_MULTISELECT_PROPS = {
    [CMS_GLOBAL_CONFIG]: ['zowieChatEnabledPages'],
    [CMS_SMART_BANNER_CONFIG]: ['enabledPages'],
};

export const VALID_INTERPRETER_ROOT_TAGS = ['div', 'span', 'ul'];

export const MATCH_WITH_ATTRIBUTES_SELECTOR = '(.*?)';

export const CMS_TEXT_ALLOWED_HTML_TAGS = [
    'b',
    'br',
    ...['strong', 'p', 'a', 'ul', 'ol', 'li', 'div'].map(tagName =>
        tagName.concat(MATCH_WITH_ATTRIBUTES_SELECTOR)
    ),
];

export default {
    CMS_COMPONENTS_PREFIX,
};
