import {
    CATALOG_PAGE_NAME,
    SEARCH_RESULTS_PAGE_NAME,
} from '@search/routing/names';

export const ROUTES_USING_GENERATE_AVATAR_ID_IN_SSR = [
    CATALOG_PAGE_NAME,
    SEARCH_RESULTS_PAGE_NAME,
];

export const GENERATE_AVATAR_ID_IN_SSR_TEST_NAME =
    'eob_all_generate_avatar_id_in_ssr';

export const VARIANT_ON = 'on';

export const AVATAR_ID_CREATION_TIMEOUT_THRESHOLD_IN_MILLISECONDS = 250;
