import {
    VALID_QUERY_ORDER_KEYS,
    QUERY_ORDER,
    QUERY_ORDER_DIR,
} from '@configs/query-keys/sort';
import {
    QUERY_PAGE_SIZE,
    QUERY_CURRENT_PAGE,
} from '@configs/query-keys/pagination';
import { VALID_QUERY_SEARCH_KEYS } from '@configs/query-keys/search';
import {
    ASC,
    DESC,
    CREATED,
    PRICE,
    DEFAULT,
} from '@configs/defaults/query/sort';

import {
    stringifyCategoryUrl,
    stringifySearchUrl,
    filtersToParamArray,
} from '@assets/path';

import { SEARCH_RESULTS_PAGE_NAME } from '@search/routing/names';

import { FORMAT_YYYYMMDD_HHMM } from '@types/DateFormat';
import { EMPTY } from '@types/Filters';

import { MODULE_NAME as CATALOG_MODULE_NAME } from '@analytics-modules/catalog/meta';
import {
    CATEGORY_FILTER_SET,
    CATEGORY_FILTER_PRE_SET,
    CATEGORY_SORT_DEFAULT,
    CATEGORY_SORT_NEWEST,
    CATEGORY_SORT_PRICE_ASC,
    CATEGORY_SORT_PRICE_DESC,
} from '@analytics-modules/catalog/types/Events';

import DateHelper from '@models/DateHelper/DateHelper';

const sortOptionsEventsMap = {
    [DEFAULT]: CATEGORY_SORT_DEFAULT,
    [CREATED]: {
        [DESC]: CATEGORY_SORT_NEWEST,
    },
    [PRICE]: {
        [DESC]: CATEGORY_SORT_PRICE_DESC,
        [ASC]: CATEGORY_SORT_PRICE_ASC,
    },
};

export function shouldBeKeptAfterFiltersChange(param) {
    const VALID_QUERY_KEYS_AFTER_FILTERS_CHANGE = [
        QUERY_PAGE_SIZE,
        ...VALID_QUERY_SEARCH_KEYS,
        ...VALID_QUERY_ORDER_KEYS,
    ];

    return VALID_QUERY_KEYS_AFTER_FILTERS_CHANGE.includes(param);
}

function onCategoryRoute(
    clearedQuery,
    filters,
    categoryUrl,
    createCategoryPath
) {
    const categoryPath = stringifyCategoryUrl({
        categoryUrl,
        filters,
    });

    return {
        path: createCategoryPath(categoryPath),
        query: {
            ...clearedQuery,
        },
    };
}

function onSearchRoute(clearedQuery, filters, query, createSearchPath) {
    const searchPath = stringifySearchUrl({
        query,
        filters,
    });

    return {
        path: createSearchPath(searchPath),
        query: {
            ...clearedQuery,
        },
    };
}

export function createRouterConfigToApply(
    { $route, $filterManager, $createCategoryPath, $createSearchPath, $store },
    isSearchPage
) {
    const { query: routeQuery } = $route;

    const { query: stateQuery, url: categoryUrl } = $store.state.category;

    const clearedQuery = Object.keys(routeQuery).reduce((query, urlParam) => {
        const shouldBeKept = shouldBeKeptAfterFiltersChange(urlParam);

        if (shouldBeKept) {
            query[urlParam] = routeQuery[urlParam];
        }

        return query;
    }, {});

    const { selectedFilters, selectedExtraFilters } = $filterManager;

    const filtersToApply = {
        ...selectedFilters,
        ...selectedExtraFilters,
    };

    if (isSearchPage) {
        return onSearchRoute(
            clearedQuery,
            filtersToApply,
            stateQuery,
            $createSearchPath
        );
    }

    return onCategoryRoute(
        clearedQuery,
        filtersToApply,
        categoryUrl,
        $createCategoryPath
    );
}

export function getQueryWithoutOrderAndPagination(query) {
    const {
        [QUERY_CURRENT_PAGE]: omitPagination,
        [QUERY_ORDER]: omitOrder,
        [QUERY_ORDER_DIR]: omitOrderDir,
        ...clearedQuery
    } = query;

    return clearedQuery;
}

export function applyOrderSettings(query, sortOption, defaultCode) {
    const { code, order } = sortOption;

    if (code && code !== defaultCode) {
        return {
            ...query,
            [QUERY_ORDER]: code,
            [QUERY_ORDER_DIR]: order,
        };
    }

    return query;
}

export function isFilterActive(storeViewTimezone, startDate, endDate) {
    const dateHelper = new DateHelper(storeViewTimezone, FORMAT_YYYYMMDD_HHMM);

    const actionStart = dateHelper.createDateFromStringInTimezone(startDate);

    const actionEnd = dateHelper.createDateFromStringInTimezone(endDate);

    const currentDeviceTimeInStoreTimezone = dateHelper.createCurrentDateInTimezone();

    return DateHelper.isCurrentDateBetweenPassed(
        actionStart,
        actionEnd,
        currentDeviceTimeInStoreTimezone
    );
}

export function sendAnalyticsFilterSet(
    { $analytics, $filterManager },
    code,
    isSet,
    anotherName = ''
) {
    const eventType = isSet ? CATEGORY_FILTER_SET : CATEGORY_FILTER_PRE_SET;
    const nameToSet = anotherName || code;

    $analytics.moduleEmit(CATALOG_MODULE_NAME, eventType, {
        filterNameAndValueCode:
            filtersToParamArray({
                [nameToSet]: $filterManager.selectedCodes[code],
            }).join('') || `${nameToSet}:`,
    });
}

export function applySearchConfiguration(app, isMobile) {
    const { areFiltersChanged, areExtraFiltersChanged } = app.$filterManager;
    const isSortChanged = app.$store.getters['sort/isSortChanged'];

    if (!areFiltersChanged && !isSortChanged && !areExtraFiltersChanged) {
        return;
    }

    app.$store.dispatch('category/setLoading', true);
    let { path: pathToApply, query: queryToApply } = app.$route;

    if (areFiltersChanged || areExtraFiltersChanged) {
        const isSearchPage = app.$route.name === SEARCH_RESULTS_PAGE_NAME;
        const { path, query } = createRouterConfigToApply(app, isSearchPage);

        queryToApply = query;
        pathToApply = path;
    }

    if (isSortChanged) {
        const { selectedSortOption, defaultOrderCode } = app.$store.state.sort;

        queryToApply = applyOrderSettings(
            getQueryWithoutOrderAndPagination(queryToApply),
            selectedSortOption,
            defaultOrderCode
        );

        const { code, order } = selectedSortOption;

        app.$analytics.moduleEmit(
            CATALOG_MODULE_NAME,
            sortOptionsEventsMap[code]?.[order] || sortOptionsEventsMap[DEFAULT]
        );
    }

    if (isMobile && areFiltersChanged) {
        const filterNameAndValueCode =
            filtersToParamArray(app.$filterManager.selectedCodes).join('|') ||
            EMPTY;

        app.$analytics.moduleEmit(CATALOG_MODULE_NAME, CATEGORY_FILTER_SET, {
            filterNameAndValueCode,
        });
    }

    const { path, query } = app.$route;

    if (
        pathToApply === path &&
        Object.keys(query).length === Object.keys(queryToApply).length &&
        Object.entries(query).every(
            ([key, value]) => queryToApply[key] === value
        )
    ) {
        app.$store.dispatch('category/setLoading', false);

        return;
    }

    app.$router.push({
        path: pathToApply,
        query: queryToApply,
    });
}
