<template>
    <div>
        <BaseSimpleHeader :heading="$t('Checkout')" />

        <main class="checkout-wrapper">
            <nuxt />
        </main>

        <Messages />
        <FooterSimpleWrapper />
        <ChatWrapper />
        <GlobalModals />
        <SmartBannerWrapper />
    </div>
</template>

<script>
import ChatWrapper from '@molecules/ChatWrapper/ChatWrapper';
import FooterSimpleWrapper from '@molecules/FooterSimpleWrapper/FooterSimpleWrapper';
import Messages from '@molecules/Messages/Messages';
import SmartBannerWrapper from '@molecules/SmartBanner/SmartBannerWrapper';

import BaseSimpleHeader from '@organisms/BaseSimpleHeader/BaseSimpleHeader';
import GlobalModals from '@organisms/GlobalModals/GlobalModals';

import BaseLayout from './mixins/BaseLayout';

export default {
    name: 'CheckoutLayout',

    components: {
        BaseSimpleHeader,
        Messages,
        FooterSimpleWrapper,
        ChatWrapper,
        GlobalModals,
        SmartBannerWrapper,
    },

    mixins: [BaseLayout],
};
</script>

<style lang="scss" scoped>
.checkout-wrapper {
    @apply mx-auto px-3;
    max-width: theme('customVariables.checkout.containerMaxWidth');

    :deep() .footer-free-delivery {
        @apply mt-7;
    }
}
</style>
